const actions = {
  getAll: (params = {}) => {

    const filters = params.filters;

    return {
      method: "get",
      url: "/surveys",
      params: {
        filters,
        populate: {
          image: true,
          results: { populate: "*" },
          questions: {
            on: {
              'question.multiple-choice': {
                populate: '*',
              },
              'question.scale': {
                populate: '*',
              },
            },
          },
        },
      }
    };
  },

  get: (id) => {
    return {
      method: "get",
      url: `/surveys/${id}`,
      params: {
        populate: {
          image: true,
          results: { populate: "*" },
          questions: { populate: "*" },
        },
      }
    };
  }
};

export default actions;
