import retry from 'async-retry';
import { axios } from './axios.js';


export class ApiClientError extends Error {
  constructor(error, ...options) {
    super(...options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiClientError);
    }

    this.name = "ApiClientError";

    this.client = {
      code: error.code,
      name: error.name,
      message: error.message,
    };

    if (error.response) {
      this.http = {
        code: error.response.status,
        message: error.response.statusText,
      };

      if (error.response.data?.error) {
        this.api = {
          code: error.response.data.error.status,
          name: error.response.data.error.name,
          message: error.response.data.error.message,
          details: error.response.data.error.details,
        };
      }
    }

    this.message = this.api?.message ?? this.http?.message ?? this.client.message;
    this.code = this.api?.code ?? this.http?.code ?? this.client.code;
  }
}


export async function request(config) {
  try {
    return (await axios(config));
  } catch (error) {
    throw new ApiClientError(error);
  }
}


async function apiRequest(config) {

  return await retry(
    async (bail) => {
      try {
        return await request(config);
      } catch (error) {
        if (403 === error.code || 401 === error.code || 400 === error.code) {
          bail(error);
          return;
        }
        else {
          throw error
        }
      }
    },
    {
      retries: 5,
    }
  );
}

export default apiRequest;
