import Link from '../link';
import { ReactComponent as BCcampusLogo } from './bccampus-logo.svg';

function BCcampusBadge({ label }) {

  return (
    <div>
      <Link target="_blank" href="http://www.bccampus.ca/" aria-label="Designed and developed by BCcampus">
        {label &&
          <div>{label === true ? "Designed and developed by:" : label}</div>
        }
        <BCcampusLogo className="bccampus-logo" alt="bccampus logo" role="presentation" style={{ width: "75%", padding: "0.5em 0em" }} />
      </Link>
    </div>
  );
}

export default BCcampusBadge;
