import Link from '../../../components/link';
import Button from '../../../components/button';
import surveySessionsAtom from '../../../data/atoms/survey-session';
import surveyResponsesAtom from '../../../data/atoms/survey-responses';

import startImg from '../assets/start.png';
import useUserSettings from '../../../hooks/useUserSettings';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import SimpleForm from '../components/simple-form';
import { useHistory } from 'react-router-dom';
import { DialogAtom, DialogMessageTypes } from '../components/dialog';
import { useUpdateAtom } from 'jotai/utils';

function StartPage({ sessionCode, redirectPath }) {
  const { saveUserSettings } = useUserSettings();
  const [, dispatchSessionAction] = useAtom(surveySessionsAtom);
  const [, dispatchResponseAction] = useAtom(surveyResponsesAtom);
  const history = useHistory();
  const setDialogState = useUpdateAtom(DialogAtom);

  const startSession = (session) => {
    dispatchResponseAction({
      type: "read",
      data: {
        surveySession: session.code
      },
      callback: (newResponsesState) => {
        if (newResponsesState.success) {
          saveUserSettings({
            session,
            responses: newResponsesState.data,
            competencyInProgress: undefined,
            competenciesDone: newResponsesState.data.map(r => r.survey.category)
          });
          history.replace(redirectPath);
        }
        else {
          setDialogState({
            open: true,
            message: "The kryptonite is weakening our servers; please try again later!",
            messageType: DialogMessageTypes.error,
            dismissTimer: 0,
          });
        }
      }
    });
  };

  const onStart = () => {
    dispatchSessionAction({
      type: "create",
      callback: (newSessionState) => {
        if (newSessionState.success) {
          startSession(newSessionState.data);
        }
        else {
          setDialogState({
            open: true,
            message: "The kryptonite is weakening our servers; please try again later!",
            messageType: DialogMessageTypes.error,
            dismissTimer: 0,
          });
        }
      }
    });
  };

  const onContinue = (savedCode) => {
    dispatchSessionAction({
      type: "read",
      code: savedCode,
      callback: (newSessionState) => {
        if (newSessionState.success) {
          if (newSessionState.data) {
            startSession(newSessionState.data);
          }
          else {
            setDialogState({
              open: true,
              message: "The code is invalid or expired",
              messageType: DialogMessageTypes.warn,
            });
          }
        }
        else {
          setDialogState({
            open: true,
            message: "The kryptonite is weakening our servers; please try again later!",
            messageType: DialogMessageTypes.error,
            dismissTimer: 0,
          });
        }
      }
    });
  }

  useEffect(() => {
    if (!!sessionCode) {
      onContinue(sessionCode);
    }
  },
    // eslint-disable-next-line
    [sessionCode]
  );

  return (
    <div className="centered">
      <div className="content">
        <div className="section" >
          <div>
            <div className="heading" aria-label="Find your digiral superpower">Find your</div>
            <div className="heading">
              <img src={startImg} alt="Digital Superpower" className="hero-image" />
            </div>
          </div>
          <Button className="button chubby" onClick={onStart} >START</Button>
          <div className="divider">
            <span className="heading">OR</span>
          </div>

          <SimpleForm
            placeholder="Enter a saved code to resume"
            submitLabel="Resume"
            onSubmit={onContinue}
          />
        </div>
      </div>
      <div className="footer">
        <div>This site is licensed as <Link className="link" target="_blank" href="https://creativecommons.org/licenses/by/4.0/">CC-BY</Link> except where otherwise noted.</div>
        <Link className="link" href="/superpower/credits">Credits</Link>
      </div>
    </div>
  );
}

export default StartPage;
