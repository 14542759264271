import { useAtomValue } from 'jotai/utils';

import ResultsSection from './results-section';

import ResultsFilter from './results-filter';
import NoSearchResults from './no-results';

import { useSearch, searchResultsAtom } from '../../hooks/useSearch'

import FacetedSearchSubjects from './faceted-search-subjects';
import { Paragraph, Text } from '../typography';
import Button from '../button';
import { Space } from 'antd';
import Link from '../link';
import RadioGroup from '../radio-group';
import Horizontal from '../horizontal';
import useUserSettings from '../../hooks/useUserSettings';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useEffect } from 'react';
import { faExternalLink, faFilter, faGrip, faList } from '@fortawesome/free-solid-svg-icons';

function SearchHits({
  searchResults, showFilters, showTitle, descriptionContent, showDescription, onChangeFilter,
  onViewAll, onViewAllCourses, onViewAllLearningResources, onViewAllTextbooks
}) {
  const screens = useBreakpoint();
  const [userSettings, setUserSettings] = useUserSettings();

  const toggleFilters = () => {
    setUserSettings({ filtersOpen: !userSettings.filtersOpen });
  }
  const changeLayout = (newLayout) => {
    setUserSettings({ searchViewLayout: newLayout });
  }
  const totalShown = searchResults.results.courses.hits.length +
    searchResults.results.learningResources.hits.length +
    searchResults.results.textbooks.hits.length;

  useEffect(() => {
    if (screens.lg === false && userSettings.searchViewLayout === undefined) {
      setUserSettings({ searchViewLayout: "list" })
    }
  }, [screens, userSettings.searchViewLayout, setUserSettings])

  return (
    <>
      {showTitle !== false &&
        <Horizontal gap="0.5em" style={{ marginBottom: "1em" }}>
          <Space split="|" wrap>
            <Text>{searchResults.totalHits} results found</Text>
            {
              (screens.md && searchResults.totalHits > totalShown) &&
              <Button type='text' onClick={onViewAll}>Expand all {searchResults.totalHits} results</Button>
            }
          </Space>
          <Horizontal.Spacer />
          <Button
            role="switch"
            aria-checked={userSettings.filtersOpen}
            secondary={!userSettings.filtersOpen} icon={faFilter}
            iconAlign="left" aria-label="Show filters"
            onClick={toggleFilters}
            keyBindings={{
              "Enter": toggleFilters,
              "Space": toggleFilters,
            }}
          >
            Filters
          </Button>
          <RadioGroup
            aria-label="Change search result view"
            value={userSettings.searchViewLayout}
            onChange={(value) => changeLayout(value)}
            options={[
              { icon: faGrip, value: "grid", title: "Grid view" },
              { icon: faList, value: "list", title: "List view" },
            ]} />
        </Horizontal>
      }
      {userSettings.filtersOpen &&
        <div>
          <ResultsFilter showFilters={showFilters} onChange={onChangeFilter} />
        </div>
      }
      {showDescription === true && (
        descriptionContent !== undefined
          ? descriptionContent
          :
          <div style={{ textAlign: "center" }}>
            <Text>Showing {searchResults.totalHits} total results for “{searchResults.query}”</Text>
          </div>
      )}
      <div id="bccampus-search-hits-container">
        <ResultsSection
          title="Textbooks"
          results={searchResults.results.textbooks}
          onViewAll={onViewAllTextbooks}
          cardsPerRow={{ xs: 1, md: 2, xl: 3 }}
          cardType="textbook"
          layout={userSettings.searchViewLayout}
        />

        <ResultsSection
          title="Course packs"
          results={searchResults.results.courses}
          onViewAll={onViewAllCourses}
          cardType="course"
          layout={userSettings.searchViewLayout}
        />

        <ResultsSection
          title="Other Course Materials"
          results={searchResults.results.learningResources}
          onViewAll={onViewAllLearningResources}
          cardsPerRow={{ xs: 1, md: 3, xl: 6 }}
          cardType="learning-resource"
          layout={userSettings.searchViewLayout}
        />
      </div>
    </>
  )
}

function SearchResults({ showFilters, showTitle, showFooter, showDescription,
  descriptionContent, noResultContent,
  viewAllCourses = true, viewAllLearningResources = true, viewAllTextbooks = true }) {

  const searchResults = useAtomValue(searchResultsAtom);

  const { setFilter, setSettings } = useSearch();

  const handleViewAllCourses = () => {
    setSettings({
      viewAllCourses: true
    });
  };

  const handleViewAllLearningResources = () => {
    setSettings({
      viewAllResources: true
    });
  };

  const handleViewAllTextbooks = () => {
    setSettings({
      viewAllTextbooks: true
    });
  };

  const handleViewAll = () => {
    setSettings({
      viewAllCourses: true,
      viewAllResources: true,
      viewAllTextbooks: true,
    });
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <div>
      {
        !!searchResults.query.trim() && searchResults.totalHits === 0
          ?
          noResultContent !== undefined ? noResultContent : <NoSearchResults />
          :
          searchResults.totalHits > 0
            ?
            <>
              <SearchHits
                searchResults={searchResults}
                showFilters={showFilters}
                showTitle={showTitle}
                showDescription={!!searchResults.query.trim() && showDescription}
                descriptionContent={descriptionContent}
                onChangeFilter={onChangeFilter}
                onViewAll={handleViewAll}
                onViewAllCourses={viewAllCourses && handleViewAllCourses}
                onViewAllLearningResources={viewAllLearningResources && handleViewAllLearningResources}
                onViewAllTextbooks={viewAllTextbooks && handleViewAllTextbooks}
              />
              {showFooter !== false &&
                <Paragraph style={{ backgroundColor: "var(--background-color-alt)", padding: "1.5em" }}>
                  Looking for something we don’t have? BCcampus is a highly curated collection of materials based on strict criteria,
                  but there are millions of other open resources out there. <Link href="https://oasis.geneseo.edu/" icon={faExternalLink} >Search beyond BCcampus using OASIS</Link>
                </Paragraph>
              }
            </>
            :
            <FacetedSearchSubjects style={{ marginTop: "2em" }} />
      }
      <div
        role="region"
        id="bccampus-search-result-notice"
        aria-live="polite"
        style={{ position: 'absolute', zIndex: 0, top: -1000 }}
      >
        {searchResults.totalHits > 0 ? `${searchResults.totalHits} results found` : "No results found"}
      </div>
    </div>
  )
}
export default SearchResults;
