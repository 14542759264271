import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Input, Space } from 'antd';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState, useCallback } from 'react';
import Button from '../button';

import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SearchBox({ style, value, onChange, placeholder }) {
  const [query, setQuery] = useState(value);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    const newQuery = e.target.value;
    changeQuery(newQuery);
  }

  const debouncedChange = useCallback(debounce(onChange, 500), [onChange]);

  const changeQuery = (query) => {
    setQuery(query);
    debouncedChange(query);
  }

  const handleEnter = () => {
    const firstResult = document.querySelector('.bccampus-link.card');
    const header = document.querySelector('#bccampus-search-hits-container h2');
    if (firstResult) {
      inputRef.current.blur();
      if (header) {
        header.scrollIntoView(true);
        header.focus({ preventScroll: true });
      }
    }
  };

  useEffect(() => {
    setQuery(value);
  }, [value]);

  return (
    <Space className="bccampus-search-bar" align="center" style={style}>
      <Input
        ref={inputRef}
        value={query}
        onChange={handleChange}
        onPressEnter={handleEnter}
        placeholder={placeholder}
        suffix={!!query.trim()
          ?
          <Button
            type="icon" icon={faTimes} aria-label="Clear search"
            style={{ width: "1.5em", height: "1.5em", fontSize: "1em" }}
            onClick={() => changeQuery("")}
          />
          :
          <></>
        }
        aria-label="Search"
        style={{ width: "100%" }}
        aria-controls="bccampus-search-result-notice"
      />
      <Button noShadow onClick={handleEnter} aria-label="Show search results" style={{ margin: "0.5rem 0" }}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
    </Space>
  )
}

export default SearchBox;
