import React from 'react';
import { Result, Collapse, Row, Col } from 'antd'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Result
          status="500"
          title="Sorry, something went wrong!"
          extra={
            <Row>
              <Col xl={{ span: 12, offset: 6 }} lg={{ span: 16, offset: 4 }}>
                {this.state.error && this.state.error.toString()}
                {process.env.NODE_ENV === "development" &&
                  <Collapse ghost>
                    <Collapse.Panel header="Error Details">
                      <div style={{ textAlign: "left", whiteSpace: 'pre-wrap' }}>
                        {this.state.errorInfo.componentStack}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                }
              </Col>
            </Row>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
