import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/button";
import Link from "../../../components/link";

function Header({ left, right }) {

  return (
    <div className="header">
      {!!left
        ?
        (left.href ?
          <Link
            className="link"
            aria-label={left.label}
            href={left.href}
            onClick={left.onClick}
          >
            <FontAwesomeIcon icon={left.icon} size="xl" />
          </Link>
          :
          <Button
            type="text"
            aria-label={left.label}
            onClick={left.onClick}
          >
            <FontAwesomeIcon icon={left.icon} size="xl" />
          </Button>
        )
        :
        <div style={{ width: "2em" }}></div>
      }
      {!!right
        ?
        (right.href ?
          <Link
            className="link"
            aria-label={right.label}
            href={right.href}
            onClick={right.onClick}
          >
            <FontAwesomeIcon icon={right.icon} size="xl" />
          </Link>
          :
          <Button
            type="text"
            aria-label={right.label}
            onClick={right.onClick}
          >
            <FontAwesomeIcon icon={right.icon} size="xl" />
          </Button>
        )
        :
        <div style={{ width: "2em" }}></div>
      }
    </div>
  );
}

export default Header;
