import { Space } from 'antd';
import TopMenu from '../menu';
import Horizontal from '../horizontal';
import PageSection from '../page-section';

import Link from '../../components/link';
import { BrandLogo, BrandIcon } from '../logo';
import React from 'react';

import './index.css';

function Header() {
  const menuItems = {
    'learn-it': {
      label: 'Learn it',
      to: '/learn-it',
      exactMatch: true,
    },
    'teach-it': {
      label: 'Teach it',
      to: '/teach-it',
      exactMatch: true,
    },
    'about': {
      label: 'About',
      to: '/about',
      exactMatch: true,
    },
  };

  return (
    <PageSection
      className="bccampus-site-header"
      padding="horizontal"
      style={{ height: "100%", alignItems: "center", borderBottom: "2px solid #f2f2f2" }}
    >
      <Horizontal className="large-header">
        <Horizontal.Part stretched >
          <SiteTitle />
        </Horizontal.Part>
        <Horizontal.Part stretched style={{ textAlign: "right" }}>
          <TopMenu items={menuItems} />
        </Horizontal.Part>
      </Horizontal>
      <Horizontal className="small-header">
        <Horizontal.Part stretched >
          <SiteTitle logoOnly={true} />
        </Horizontal.Part>
        <Horizontal.Part style={{ textAlign: "center" }}>
          <TopMenu items={menuItems} />
        </Horizontal.Part>
      </Horizontal>
    </PageSection>
  )
}

function SiteTitle({ style, logoOnly = false }) {
  return (
    <Space size="small" style={style}>
      {logoOnly
        ?
        <Link href="/" aria-label="Digital Literacy" style={{ outlineOffset: "-0.25em" }}>
          <img src={BrandIcon} role="presentation" alt="Digital Literacy logo" style={{ height: "4em" }} />
        </Link>
        :
        <>
          <Link href="/" header aria-label="Digital Literacy">
            <img src={BrandLogo} role="presentation" alt="Digital Literacy logo" style={{ height: "2em" }} />
          </Link>
        </>
      }
    </Space>
  )
}

function SlidingContent({ children, align, visible }) {

  return (
    <div
      style={{ textAlign: align, width: "100%", position: "relative" }}
      className={visible ? "slide-in" : "slide-out"}>
      {React.Children.map(children,
        child => (
          React.cloneElement(child, {
            "aria-hidden": !visible,
            tabIndex: visible ? 0 : -1
          })
        )
      )}
    </div>
  )
}

export { SiteTitle, SlidingContent, Header };
export default Header;
