import apiRequest from '../../utils/bccampusApiRequest.js';

import sessions from '../configs/SurveySessions.js';

const read = async (code) => {
  if (code) {
    return await getByCode(code);
  }
}

const getByCode = async (code) => {
  const result = await apiRequest(sessions.getByCode(code));
  return result.data;
}

const create = async () => {
  const result = await apiRequest(sessions.create());
  return result.data;
}

const sendCode = async (code, email) => {
  const result = await apiRequest(sessions.emailCode(code, email));
  return result.data;
}

const sendResults = async (code, email) => {
  const result = await apiRequest(sessions.emailResults(code, email));
  return result.data;
}
const repository = { read, create, sendCode, sendResults };

export { read, create, sendCode, sendResults }
export default repository
