import { useEffect, useMemo, useRef, useState } from "react";
import { faTimesCircle, faCheckCircle, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const choiceKeys = ["A", "B", "C", "D", "E", "F", "G", "H"];

function Choice({ id, label, choiceKey, multipleSelect, selected = false, correct, className, onChange }) {

  const handleSelect = () => {
    onChange(!selected, id);
  }

  const handleKeys = (e) => {
    if (e.code === "Space" || e.code === "Enter") {
      e.preventDefault();
      handleSelect();
    }
  };

  const classNames = useMemo(() => {
    const classNames = className ? [className] : [];
    if (selected) classNames.push("selected");
    if (correct === true) classNames.push("correct");
    else if (correct === false) classNames.push("incorrect");

    return classNames.join(" ")
  }, [className, selected, correct]);

  return (
    <div
      className={classNames}
      tabIndex={-1}
      role="option"
      aria-selected={selected}
      onClick={handleSelect} onKeyDown={handleKeys}
    >
      <div className="choice-key"
        aria-label={(correct !== undefined ? (correct === true ? "Correct answer" : "Incorrect answer") + (selected ? " selected, " : ", ") : choiceKey + ".")}>
        {selected
          ? (correct !== false ? "\u2714" : "\u2716")
          : choiceKey
        }
      </div>
      <div className="choice-label" dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
}

function ResultBadge({ result }) {
  if (result === undefined) return null;
  else if (result === 0)
    return (
      <div style={{ textAlign: "center", padding: "0.25rem 1rem" }}>
        <span className="paragraph error">
          <FontAwesomeIcon icon={faTimesCircle} size="lg" /> Incorrect
        </span>
      </div>
    )
  else if (result === 100)
    return (
      <div style={{ textAlign: "center", padding: "0.25rem 1rem" }}>
        <span className="paragraph success">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" /> Correct
        </span>
      </div>
    )
  else
    return (
      <div style={{ textAlign: "center", padding: "0.25rem 1rem" }}>
        <span className="paragraph">
          <FontAwesomeIcon icon={faCircleHalfStroke} size="lg" /> Partially correct
        </span>
      </div>
    )
}


function MultipleChoice({ choices, value, multipleSelect = false, readonly, answerKey, result, showExplanation = false, explanation, onChange }) {
  const [selectedChoices, setSelectedChoices] = useState(multipleSelect ? [] : null);
  const choicesRef = useRef(null);

  const handleChange = (selectedIDs) => {
    if (readonly === true) return;

    setSelectedChoices(selectedIDs);

    if (typeof onChange === "function") {
      onChange(selectedIDs);
    }
  }

  const handleChoiceChange = (choiceSelected, choiceID) => {
    if (readonly === true) return;
    if (!multipleSelect) {
      if (!choiceSelected) {
        handleChange(null);
      } else {
        handleChange(choiceID);
      }
    } else {
      if (!choiceSelected) {
        handleChange(selectedChoices.filter(v => v !== choiceID));
      } else {
        handleChange([...selectedChoices, choiceID]);
      }
    }
  }

  const classNames = ["choices"];
  if (multipleSelect) classNames.push('multiple-select');
  if (readonly) classNames.push('readonly');


  useEffect(() => {
    if (value) setSelectedChoices(value);
    else setSelectedChoices(multipleSelect ? [] : null);
  },
    // eslint-disable-next-line
    [choices]
  );

  const handleKeys = (e) => {
    const options = choicesRef.current.querySelectorAll(`[role=option]`);
    if (options.length > 1) {
      if (e.code === "ArrowRight" || e.code === "ArrowDown") {
        e.preventDefault();
        const next = document.activeElement.nextElementSibling ?? options[0];
        focusOption(next);
      }
      else if (e.code === "ArrowLeft" || e.code === "ArrowUp") {
        e.preventDefault();
        const prev = document.activeElement.previousElementSibling ?? options[options.length - 1];
        focusOption(prev);
      }
      else if (e.code === "Home") {
        e.preventDefault();
        focusOption(options[0]);
      }
      else if (e.code === "End") {
        e.preventDefault();
        focusOption(options[options.length - 1]);
      }
    }
  }

  const handleFocus = (e) => {
    if (choicesRef.current === e.target && !choicesRef.current.contains(e.relatedTarget)) {
      choicesRef.current.tabIndex = -1;
      const intialFocus = choicesRef.current.querySelector(`[role=option][aria-selected=true]`) ?? choicesRef.current.querySelector(`[role=option]`);
      if (intialFocus) {
        focusOption(intialFocus);
      }
    }
  }

  const handleBlur = (e) => {
    if (!choicesRef.current.contains(e.relatedTarget)) {
      choicesRef.current.tabIndex = 0;
    }
  }

  const focusOption = (option) => {
    option.focus({ preventScroll: true });
  }

  return showExplanation !== true ? (

    <>
      <ResultBadge result={result} />
      <div
        ref={choicesRef}
        className={classNames.join(' ')}
        role="listbox"
        tabIndex={0}
        aria-label="Choices"
        aria-multiselectable={multipleSelect}
        aria-readonly={readonly}
        onKeyDown={handleKeys}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {
          choices.map((choice, index) => (
            <Choice
              key={choice.id}
              id={choice.id}
              label={choice.label}
              choiceKey={choiceKeys[index]}
              selected={!!selectedChoices ? (!multipleSelect ? (choice.id === selectedChoices) : selectedChoices.includes?.(choice.id)) : undefined}
              correct={Array.isArray(answerKey) ? (answerKey.includes(choice.id) ? true : false) : undefined}
              multipleSelect={multipleSelect}
              onChange={handleChoiceChange}
            />
          ))
        }
      </div>
    </>
  ) : (
    <div>
      <div style={{ paddingBottom: "1rem" }}>
        <span style={{ fontWeight: "700" }}>
          {multipleSelect === true
            ? "The correct answers are:"
            : "The correct answer is:"
          }
        </span>
        {
          choices.map((choice, index) =>
            answerKey.includes(choice.id)
              ? <div key={choice.id} style={{ paddingLeft: "1rem" }}>{choiceKeys[index]}) {choice.label}</div>
              : null
          )
        }
      </div>
      {explanation &&
        <div className="paragraph" dangerouslySetInnerHTML={{ __html: explanation }} />
      }
    </div>
  );
}

export default MultipleChoice;
