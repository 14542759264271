import { useMemo, useRef, useState } from 'react';
import Button from '../button';

import './index.css';

const RadioGroup = ({ options = [], value, defaultValue, onChange, className, ...restProps }) => {
  const [controlledValue, setControlledValue] = useState(defaultValue ?? options[0]?.value);
  const toggleRef = useRef(null);

  const selectedValue = useMemo(() => value ?? controlledValue, [value, controlledValue]);

  const classNames = useMemo(() => {
    const classNames = ['bccampus-toggle'];
    if (className) classNames.push(className);
    return classNames.join(' ');
  }, [className]);

  if (!Array.isArray(options) || options.length === 0) return null;

  const handleChange = (newValue) => {
    if (newValue !== selectedValue) {
      setControlledValue(newValue);
      focusOnSelected(newValue);
      if (typeof onChange === "function") onChange(newValue);
    }
  }

  const focusOnSelected = (newSelectedValue) => {
    const selected = toggleRef.current.querySelector(`[role=radio][value=${newSelectedValue}]`) ?? toggleRef.current.querySelector(`[role=radio]`);
    if (selected) {
      selected.ariaChecked = true;
      selected.focus();
    }
  }

  const handleKeys = (e) => {
    const options = toggleRef.current.querySelectorAll(`[role=radio]`);
    const selected = toggleRef.current.querySelector(`[role=radio][value=${selectedValue}]`);

    if (selected && options.length > 1) {
      if (e.code === "ArrowRight" || e.code === "ArrowDown") {
        e.preventDefault();
        const next = selected.nextElementSibling ?? options[0];
        const newValue = next.getAttribute('value');
        selected.ariaChecked = false;
        selected.blur();
        handleChange(newValue);
      }
      else if (e.code === "ArrowLeft" || e.code === "ArrowUp") {
        e.preventDefault();
        const prev = selected.previousElementSibling ?? options[options.length - 1];
        const newValue = prev.getAttribute('value');
        selected.ariaChecked = false;
        selected.blur();
        handleChange(newValue);
      }
      else if (e.code === "Enter" || e.code === "Space") {
        e.preventDefault();
        const newValue = selected.getAttribute('value');
        handleChange(newValue);
      }
    }
  }

  return (
    <div
      ref={toggleRef}
      {...restProps}
      className={classNames}
      role="radiogroup"
      onKeyDown={handleKeys}
    >
      {
        options.map((o, index) =>
          <Button
            key={o.value}
            role="radio"
            tabIndex={selectedValue === o.value ? 0 : -1}
            icon={o.icon}
            type={o.label ? "default" : "icon"}
            secondary={selectedValue !== o.value}
            aria-label={o.title}
            title={o.title}
            value={o.value}
            className={(index === 0) ? "first-toggle-item" : (index === options.length - 1) ? "last-toggle-item" : "toggle-item"}
            onClick={() => handleChange(o.value)}
            aria-checked={selectedValue === o.value}
          >
            {o.label}
          </Button>)
      }
    </div>
  );
};

export default RadioGroup;
