import { useRef, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai/utils';
import { Row, Col, Alert } from 'antd';

import SearchResults from './results';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { useSearch, searchQueryAtom } from '../../hooks/useSearch';
import SearchBox from '../search-box';
import { Text } from '../typography';

function Search({
  id, style, query, subject, showSearchBox = true, descriptionContent, showTitle, showFooter,
  showFilters, showDescription, noResultContent, viewAllCourses, viewAllLearningResources, viewAllTextbooks }
) {
  const [previousQuery, setPreviousQuery] = useState("");

  const searchQuery = useAtomValue(searchQueryAtom);
  const containerRef = useRef(null);
  const location = useLocation();

  const { search, setSearchParams, clearResults, refreshFacets, isHealthy } = useSearch();

  useEffect(() => {
    if (isHealthy) {
      if (subject || location.search || query) {
        const searchParams =
          (location.search)
            ? parse(location.search, { arrayFormat: "bracket-separator", parseBooleans: true, sort: false })
            : {};
        const q = searchParams.q || query || "";
        const subjectFilters = new Set(searchParams.subjects ? searchParams.subjects : []);
        if (subject) subjectFilters.add(subject);
        search(q,
          {
            subjects: [...subjectFilters],
            type: searchParams.type,
            educationalLevels: searchParams.educationalLevels
          },
          {
            viewAllCourses: searchParams.viewAllCourses,
            viewAllResources: searchParams.viewAllResources,
            viewAllTextbooks: searchParams.viewAllTextbooks
          },
          true
        );
        setPreviousQuery(q);
      } else {
        clearResults();
        refreshFacets();
      }
    }
  },
    // eslint-disable-next-line
    [location, subject, query, isHealthy]
  );

  const onChangeSearchBox = (newQuery) => {
    if (isHealthy) {
      if (!newQuery.startsWith(previousQuery)) {
        setSearchParams({
          query: newQuery,
          settings: {
            viewAllCourses: false,
            viewAllResources: false,
            viewAllTextbooks: false
          }
        });
      }
      else if (newQuery !== previousQuery) {
        setSearchParams({
          query: newQuery
        });
      }
    }
  };

  return (
    <div ref={containerRef} id={id} style={style} className="bccampus-search-container">
      {
        isHealthy === true
          ?
          <>
            {showSearchBox &&
              <Row gutter={12} style={{ marginBottom: "1em" }}>
                <Col xs={24} >
                  <SearchBox onChange={debounce(onChangeSearchBox, 300)} value={searchQuery} />
                </Col>
              </Row>
            }
            <SearchResults
              showFilters={showFilters}
              showTitle={showTitle}
              showFooter={showFooter}
              showDescription={showDescription}
              descriptionContent={descriptionContent}
              noResultContent={noResultContent}
              viewAllCourses={viewAllCourses}
              viewAllLearningResources={viewAllLearningResources}
              viewAllTextbooks={viewAllTextbooks}
            />
          </>
          :
          isHealthy === false
            ?
            <Alert
              type="warning" showIcon
              message={<Text>We are currently experiencing a technical difficulty with our search engine and working to solve the issue.
                Sorry for the inconvenience.</Text>}
            />
            :
            <Alert style={{ backgroundColor: "#ffffff" }}
              type="info" showIcon
              message={<Text>It seems our search engine is having difficulty responding to us; please, wait a bit while we retry.</Text>}
            />
      }
    </div>
  )
}

export default Search;
