import { useState, useRef, useEffect } from 'react';
import FilterTag from '../filter-tag';

import './index.css';
import { Text } from '../../typography';

function FilterTagGroup({ onChange, label, name, filters, values, multiselectable = true, ...restProps }) {
  const [selectedFilters, setSelectedFilters] = useState(values || []);
  const [active, setActive] = useState(false);
  const [focused, setFocused] = useState(null);
  const groupRef = useRef(null);

  const getClass = () => {
    const classNames = ['bccampus-filter-tag-group']
    if (active === true) classNames.push('active');
    return classNames.join(' ');
  };

  const handleChange = (checked, value) => {
    const current = [...selectedFilters];
    const index = current.indexOf(value);
    if (checked === true && index === -1) current.push(value);
    else if (checked === false && index > -1) current.splice(index, 1);

    setSelectedFilters(current);
    if (onChange) onChange(name, current);
  };

  const getSelectedTags = () => {
    const selectedTags = [];
    for (let i = 0; i < Math.min(selectedFilters.length, 1); i++) {
      const filter = filters.find(f => f[0] === selectedFilters[i]);
      if (filter) selectedTags.push(filter);
    }
    return (
      selectedTags.map(filter =>
        <FilterTag key={filter[0]}
          checked icon={false}
          ellipsis={true}
          maxWidth={(selectedFilters.length > 1) ? "calc(100% - 11em)" : "calc(100% - 5.75em)"}
          label={filter[0]} hitCount={filter[1]}
        />
      )
    );
  };

  const focusOption = (option) => {
    const focusedValue = option.getAttribute('value');
    setFocused(focusedValue);
    option.focus({ preventScroll: true });
  }

  const handleFocus = (e) => {
    if (!active) {
      setActive(true);
    }
  };

  const handleBlur = (e) => {
    if (!groupRef.current.contains(e.relatedTarget)) {
      setActive(false);
    }
  };

  const handleKeys = (e) => {
    const options = groupRef.current.querySelectorAll(`[role=option]`);
    if (groupRef.current.contains(document.activeElement) && options.length > 1) {
      if (e.code === "ArrowRight" || e.code === "ArrowDown") {
        e.preventDefault();
        const next = document.activeElement.nextElementSibling ?? options[0];
        focusOption(next);
      }
      else if (e.code === "ArrowLeft" || e.code === "ArrowUp") {
        e.preventDefault();
        const prev = document.activeElement.previousElementSibling ?? options[options.length - 1];
        focusOption(prev);
      }
      else if (e.code === "Home") {
        e.preventDefault();
        focusOption(options[0]);
      }
      else if (e.code === "End") {
        e.preventDefault();
        focusOption(options[options.length - 1]);
      }
    }
  }

  useEffect(() => {
    if (active) {
      const selected = groupRef.current.querySelector(`[role=option][aria-selected=true]`) ??
        groupRef.current.querySelector(`[role=option][value="${focused}"]`) ??
        groupRef.current.querySelector(`[role=option]`);

      if (selected) {
        focusOption(selected);
      }
    }
  },
    // eslint-disable-next-line
    [active]
  );

  useEffect(
    () => { setSelectedFilters(values || []); },
    [values]
  );

  return (
    <div
      ref={groupRef} className={getClass()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeys}
      tabIndex={active ? -1 : 0}
      {...restProps}
    >
      <div className="bccampus-filter-tag-group-title">
        <Text
          id={`bccampus-filter-${name}-label`}
          aria-label={`${label} filter`}
          aria-hidden
          strong
        >
          {label}:
        </Text>
        {selectedFilters.length === 0
          ?
          <Text aria-label="Show all">All</Text>
          :
          <>
            {getSelectedTags()}

            {selectedFilters.length > 1 &&
              <FilterTag icon={false}
                label={`+${selectedFilters.length - 1} more`}
                title={`${selectedFilters.length - 1} more selected`}
                style={{ position: "absolute", right: "0.5em", margin: "0em" }}
              />
            }
          </>
        }
      </div>
      <div
        role="listbox"
        name={name}
        aria-expanded={active}
        aria-activedescendant={`bccampus-filter-${name}-option-${selectedFilters.indexOf(focused) ?? 0}`}
        aria-labelledby={`bccampus-filter-${name}-label`}
        aria-multiselectable={multiselectable}
        className="bccampus-filter-tag-group-body"
        tabIndex={-1}
      >
        {
          filters.map((filter, index) => (
            <FilterTag
              key={filter[0]}
              id={`bccampus-filter-${name}-option-${index}`}
              focused={focused === filter[0]}
              checkable
              checked={selectedFilters.indexOf(filter[0]) > -1}
              value={filter[0]}
              maxWidth="100%"
              ellipsis
              label={filter[0]} hitCount={filter[1]}
              onChange={handleChange}
            />
          ))
        }
      </div>
    </div >
  )
}

export default FilterTagGroup;
