import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Link from "../../../components/link";
import Banners from "../components/banners";
import Header from "../components/header";


function CreditsPage() {

  return (
    <div className="default">
      <Header
        left={{
          icon: faArrowLeft,
          href: "/superpower"
        }}
      />
      <div className="content" style={{ display: "flex", flexDirection:"column" }}>
        <div className="section centered" style={{ flex: "auto" }}>
          <div className="heading" style={{ marginBottom: "1em" }}>Credits</div>
          <div className="action">
            <p className="title">Icons</p>
            <Link href="https://www.flaticon.com/packs/video-game-elements-6" title="Video game elements icons">
              'Video Game Elements' icon pack created by juicy_fish - Flaticon
            </Link>
          </div>

        </div>
        <Banners />
      </div>
    </div>
  );
}

export default CreditsPage;
