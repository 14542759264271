import Link from '../../../components/link';

import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import SurveyAtomFactory from '../../../data/atoms/surveys';
import useUserSettings from '../../../hooks/useUserSettings';
import Button from '../../../components/button';
import ResultCard from '../components/result-card';
import { useRef } from 'react';
import { faExternalLink, faBolt } from '@fortawesome/free-solid-svg-icons';
import { DialogAtom, DialogTypes } from '../components/dialog';

function CategoryResultPage() {
  const surveys = useAtomValue(SurveyAtomFactory);
  const setDialogState = useUpdateAtom(DialogAtom);
  const { userSettings, saveUserSettings } = useUserSettings();
  const sectionRef = useRef();

  sectionRef.current?.focus();

  if (!userSettings.competencyInProgress) return null;

  const { key, score = 0 } = userSettings.competencyInProgress;
  const survey = surveys.data.find(survey => survey.category === key);
  const result = survey.results.find(result => result.minScore <= score && score <= result.maxScore);

  const onReviewAnswers = () => {
    saveUserSettings({
      competencyInProgress: {
        page: 0,
      }
    }, true);
  }

  return (
    <div className="default">
      <div className="content">
        <div className="category-result section" ref={sectionRef} tabIndex={-1}>
          <div className="heading">{result.header}</div>
          <ResultCard title={survey.name} text={result.text} imageUrl={survey.image?.url} />
          <div style={{ marginTop: "1em", fontWeight: "800" }}>Score {Math.round(score)}%</div>
          <div>
            <Button type="text" onClick={onReviewAnswers}>Review your answers</Button>
          </div>
        </div>
      </div>
      <div className="footer">
        <Button
          className="button"
          onClick={() => { saveUserSettings({ competencyInProgress: undefined }) }}
        >
          Keep Exploring
        </Button>
        <Button
          className="button"
          icon={faBolt}
          description="Learn more about this competency"
          onClick={() => {
            setDialogState({
              open: true,
              type: DialogTypes.confirm,
              positiveActionLabel: "OK",
              negativeActionLabel: "Back",
              title: "Power Up",
              message: "You will be taken to the B.C. Digital Literacy learning page. We will keep your quiz open in this tab.",
              onPositiveAction: () => window.open(`/competencies/${survey.category}`, "_bcdl"),
            })
          }
          }
        >
          Power Up
        </Button>

        <div>
          <Link className="link" href="/superpower/save">Save & Leave</Link>
        </div>
      </div>
    </div>
  );
}

export default CategoryResultPage;
