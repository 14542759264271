import { GraphQLClient } from 'graphql-request'
import retry from 'async-retry';
import transformStrapiV4Response from './transformStrapiV4Response';

const endpoint = process.env.REACT_APP_OOC_GRAPHQL_ENDPOINT;

class ApiRequestError extends Error {
  constructor(code, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiRequestError)
    }

    this.name = 'ApiRequestError'
    this.code = code
  }
}

async function apiRequest(query, variables) {

  return await retry(
    async (bail) => {
      try {
        const graphQLClient = new GraphQLClient(endpoint);
        const data = await graphQLClient.request(query, variables);
        transformStrapiV4Response(data);
        return data;
      } catch (error) {
        let code = 500;
        let message = "Unknown Error";

        if (!!error.response) {
          code = error.response.status;
          if (!!error.response.error) {
            message = `${error.response.error.name}: ${error.response.error.message}`;
          }
          else if (!!error.response.errors) {
            const payload = error.response.errors[0];

            if (/forbidden/i.test(payload.extensions?.code)) code = 403;

            if (!!payload.extensions.exception) {
              message = `${payload.extensions.code}: ${payload.message}`;
            }
            else {
              message = `${payload.extensions.error.name}: ${payload.extensions.error.message}`;
            }
          }
        }

        const requestError = new ApiRequestError(code, message);

        if (403 === code || 401 === code|| 400 === code) {
          bail(requestError);
          return;
        }
        else {
          throw requestError
        }
      }
    },
    {
      retries: 5,
    }
  );
}

export default apiRequest;
