import useUserSettings from '../../../hooks/useUserSettings';
import Button from '../../../components/button';
import Header from '../components/header';
import { useUpdateAtom } from 'jotai/utils';
import { DialogAtom, DialogMessageTypes } from '../components/dialog';
import { useHistory } from 'react-router-dom';
import { faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons';


function SaveSessionPage() {
  const { userSettings } = useUserSettings();
  const setDialogState = useUpdateAtom(DialogAtom);
  const history = useHistory();

  if (!userSettings.session?.code) return null;

  const copyCode = () => {
    try {
      navigator.clipboard.writeText(userSettings.session.code);
      setDialogState({
        open: true,
        message: "Copied the session code",
        messageType: DialogMessageTypes.success,
      });
    } catch (error) {
      setDialogState({
        open: true,
        title: "Error",
        message: error?.message,
        messageType: DialogMessageTypes.error,
      });
    }
  }

  return (
    <div className="default">
      <Header
        left={{
          icon: faArrowLeft,
          onClick: () => history.goBack()
        }}
      />
      <div className="content">
        <div className="section" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "4em" }}>
          <div className="heading">Leaving?</div>
          <div className="text">We’ll keep this session for a little while if you feel like coming back!</div>
          <div style={{ display: "flex", gap: "0.5em", flexDirection: "column", alignItems: "center" }}>
            <Button
              className="button chubby"
              onClick={copyCode}
              aria-label="Copy session code"
              icon={faCopy}
            >
              {userSettings.session.code}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveSessionPage;
