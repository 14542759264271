import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useAtomValue } from 'jotai/utils';

import FilterTagGroup from './filter-tag-group';
import { searchResultsAtom, searchFilterAtom } from '../../hooks/useSearch';
import sumObjectValuesByKey from '../../utils/sumObjectValuesByKey';

function ResultsFilter({ showFilters = ['subjects', 'type', 'educationalLevels'], onChange, ...restProps }) {
  const searchFilter = useAtomValue(searchFilterAtom);
  const searchResults = useAtomValue(searchResultsAtom);
  const [filters, setFilters] = useState(searchFilter);

  const subjectFilters = (showFilters.includes("subjects")) ? sumObjectValuesByKey(
    searchResults.results.courses.facetDistribution?.subjects,
    searchResults.results.textbooks.facetDistribution?.subjects,
    searchResults.results.learningResources.facetDistribution?.subjects
  ) : null;

  const typeFilters = (showFilters.includes("type")) ? sumObjectValuesByKey(
    searchResults.results.courses.facetDistribution?.type,
    searchResults.results.textbooks.facetDistribution?.type,
    searchResults.results.learningResources.facetDistribution?.type
  ) : null;

  const levelFilters = (showFilters.includes("educationalLevels")) ? sumObjectValuesByKey(
    searchResults.results.courses.facetDistribution?.educationalLevels,
    searchResults.results.textbooks.facetDistribution?.educationalLevels,
    searchResults.results.learningResources.facetDistribution?.educationalLevels
  ) : null;

  const onChangeFilterGroup = (name, selectedFilters) => {
    const current = { ...filters };
    current[name] = selectedFilters;
    setFilters(current);

    if (onChange) onChange(current);
  };

  useEffect(
    () => {
      setFilters(searchFilter)
    },
    [searchFilter]
  );

  return (
    <Row gutter={12} justify="center">
      {subjectFilters &&
        <Col xs={24} md={8} xl={6}>
          <FilterTagGroup
            key="subjects"
            name="subjects"
            label="Subjects"
            values={filters.subjects}
            filters={Object.entries(subjectFilters).sort((f, s) => s[1] - f[1])}
            onChange={onChangeFilterGroup} />
        </Col>
      }
      {typeFilters &&
        <Col xs={24} md={8} xl={6}>
          <FilterTagGroup
            key="type"
            name="type"
            label="Material Type"
            values={filters.type}
            filters={Object.entries(typeFilters).sort((f, s) => s[1] - f[1])}
            onChange={onChangeFilterGroup} />
        </Col>
      }
      {levelFilters &&
        <Col xs={24} md={8} xl={6}>
          <FilterTagGroup
            key="educationalLevels"
            name="educationalLevels"
            label="Level"
            values={filters.educationalLevels}
            filters={Object.entries(levelFilters).sort((f, s) => s[1] - f[1])}
            onChange={onChangeFilterGroup} />
        </Col>
      }
    </Row>
  )
}

export default ResultsFilter;
