import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SpinnerIcon = ({ icon = faSpinner, iconSize = "1x", iconSpin = true, spinDuration = "3s" }) => (

  <FontAwesomeIcon
    icon={icon} spin={iconSpin} size={iconSize}
    style={{
      "--fa-animation-duration": spinDuration,
      "--fa-animation-timing": "cubic-bezier(.17,.76,.84,.27)",
      color: "var(--primary-color)"
    }} />
);

export default SpinnerIcon;
