import { Helmet } from "react-helmet";
import { getEnvVar, IS_PRODUCTION, IS_SNAP } from "../utils/envVar";

const GOOGLE_ANALYTICS_ID = getEnvVar('REACT_APP_GOOGLE_ANALYTICS_ID');

const GoogleAnalytics = () => {
  if (!IS_PRODUCTION || IS_SNAP || !GOOGLE_ANALYTICS_ID) return null;

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){
                dataLayer.push(arguments);
            }
            gtag('js', new Date()); gtag('config', '${GOOGLE_ANALYTICS_ID}');
            `}
      </script>
    </Helmet>
  )
};

export default GoogleAnalytics;
