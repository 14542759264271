import { MeiliSearch } from 'meilisearch';
import retry from 'async-retry';
import sumObjectValuesByKey from './sumObjectValuesByKey';

const searchClient = (function () {
  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILISEARCH_API_ENDPOINT,
    apiKey: process.env.REACT_APP_MEILISEARCH_PUBLIC_KEY,
  });

  const facets = ["concepts.subject", "type.code", "concepts.educational-level"];

  const isHealthy = async () => {

    try {
      return await retry(
        async () => {
          const result = await client.isHealthy();
          // We need to throw an error to retry
          if (result === false) throw new Error("Search engine is not healthy.");

          return true;
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch (error) {
      return false;
    }
  };


  const refreshFacets = async () => {
    const options = {
      facets,
      hitsPerPage: 0
    };

    try {
      return await retry(
        async () => {
          const [courses, learningResources, textbooks] = await Promise.all([
            client.index('courses').search('', options),
            client.index('learning-resources').search('', options),
            client.index('textbooks').search('', options),
          ]);

          return {
            subjects: sumObjectValuesByKey(
              courses.facetDistribution?.subjects,
              learningResources.facetDistribution?.subjects,
              textbooks.facetDistribution?.subjects
            ),
            type: sumObjectValuesByKey(
              courses.facetDistribution?.type,
              learningResources.facetDistribution?.type,
              textbooks.facetDistribution?.type
            ),
            educationalLevels: sumObjectValuesByKey(
              courses.facetDistribution?.educationalLevels,
              learningResources.facetDistribution?.educationalLevels,
              textbooks.facetDistribution?.educationalLevels
            ),
          };
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch (error) {
      return {
        subjects: {},
        type: {},
        educationalLevels: {},
      };
    }
  };

  const search = async (query, filter, settings) => {
    try {
      return await retry(
        async () => {
          const resources = await client.index('resource').search(query, {
            filter: ["currentState = published", ...(filter ?? [])],
            facets,
            hitsPerPage: settings.limit
          });

          return {
            query: query,
            totalHits: resources.totalHits,
            results: resources
          };
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch {
      return {
        query: query,
        totalHits: 0,
        results: {}
      };
    }
  };

  const multiSearch = async (query, filters = [], settings) => {
    try {
      return await retry(
        async () => {
          const results = await client.multiSearch({
            queries: filters.map(filter => ({
              filter: ["currentState = published", ...(filter ?? [])],
              indexUid: "resource",
              q: query,
              facets,
              hitsPerPage: settings.limit
            }))
          });
          return results;
        },
        {
          retries: 5,
          minTimeout: 500,
        }
      );
    } catch {
      return {
        results: []
      };
    }
  };

  return {
    refreshFacets,
    search,
    multiSearch,
    isHealthy,
  }
})();

export default searchClient;
