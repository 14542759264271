import React from 'react';
import { lazy } from "@loadable/component";
import { PrerenderedComponent } from "react-prerendered-component";

const prerenderedLoadable = dynamicImport => {
  const LoadableComponent = lazy(dynamicImport);
  return React.memo(props => (
    <PrerenderedComponent
      live={LoadableComponent.load(props)}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

export default prerenderedLoadable;
