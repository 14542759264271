
import { forwardRef } from 'react';
import { getFocusableChilds } from '../../utils/query-focusables';

const FocusTrap = forwardRef(({ children, ...restProp }, focusContainerRef) => {
  const START = "bccampus-focus-trap-start";
  const END = "bccampus-focus-trap-end";

  return (
    <div id="bccampus-focus-trap" {...restProp}
      onBlur={(e) => {
        const targetTraped = focusContainerRef.current.contains(e.target);
        const relatedTargetTraped = focusContainerRef.current.contains(e.relatedTarget);

        if (targetTraped && relatedTargetTraped) {
          return;
        }

        const focusableElements = getFocusableChilds(focusContainerRef.current);
        e.preventDefault();
        e.stopPropagation();

        if (e.relatedTarget?.id === END) focusableElements[0]?.focus();
        else if (e.relatedTarget?.id === START) {
          focusableElements.pop()?.focus();
        }
      }}
    >
      <span id="bccampus-focus-trap-start" tabIndex={0} aria-hidden />
      <div ref={focusContainerRef} tabIndex={-1}>
        {children}
      </div>
      <span id="bccampus-focus-trap-end" tabIndex={0} aria-hidden />
    </div>
  );
});

export default FocusTrap;
