import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import SurveyAtomFactory from '../../../data/atoms/surveys';
import useUserSettings from '../../../hooks/useUserSettings';
import Button from '../../../components/button';
import ResultCard from '../components/result-card';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Link from '../../../components/link';


import starsImg from '../assets/stars.png';
import Banners from '../components/banners';
import TakeActionsPage from './take-action';
import { DialogAtom, DialogTypes } from '../components/dialog';
import { faBolt, faPrint } from '@fortawesome/free-solid-svg-icons';


function AssessmentResultPage({ section }) {
  const surveys = useAtomValue(SurveyAtomFactory);
  const { userSettings, resetUserSettings } = useUserSettings();
  const history = useHistory();
  const setDialogState = useUpdateAtom(DialogAtom);
  const sectionRef = useRef();
  sectionRef.current?.focus();

  useEffect(() => {
    if (Array.isArray(userSettings.competenciesDone)) {
      const allDone = surveys.data.every(survey => userSettings.competenciesDone?.includes(survey.category));
      if (!allDone) {
        history.replace("/superpower");
      }
    }
  }, [surveys, userSettings, history]);

  const onStartOver = () => {
    setDialogState({
      open: true,
      type: DialogTypes.confirm,
      title: "Start Over",
      message: "Are you sure?",
      onPositiveAction: () => {
        resetUserSettings();
        history.replace("/superpower");
      },
    });
  }

  if (!userSettings.responses) return null;

  let totalScore = 0;
  const results = userSettings.responses.sort((a, b) => b.score - a.score).reduce((results, response) => {
    const survey = surveys.data.find(survey => survey.category === response.survey.category);
    const result = survey.results.find(result => result.minScore <= response.score && response.score <= result.maxScore);

    const card = <ResultCard key={response.id} title={survey.name} text={result.text} imageUrl={survey.image?.url} />;
    const key = result.category.replace("_", " ");

    if (results[key]) results[key].push(card);
    else results[key] = [<div key={result.category} className="heading">{key}</div>, card];

    totalScore += response.score;
    return results;
  }, {});

  const avgScore = Math.round(totalScore / userSettings.responses.length);
  if (section === "actions") return <TakeActionsPage />

  return (
    <div className="default assessment-result">
      <div className="content">
        <div className="assessment-result-header section" ref={sectionRef} tabIndex={-1} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
          <div style={{ fontSize: "2em", fontWeight: "800" }} >Finished!</div>
          <img src={starsImg} alt="Stars" style={{ width: "clamp(10rem, 60vw, 20rem)" }} role="presentation" />
          <div style={{ fontSize: "1em", fontWeight: "800" }} >Final score {avgScore}%</div>
          <div className="actions">
            <Link href="/superpower">Review answers</Link>
            <span style={{ padding: "0em 0.5em" }}>|</span>
            <Button type="text" onClick={onStartOver} >Start over</Button>
          </div>
        </div>
        <div className="assessment-result-details section">
          {Object.values(results)}
        </div>
        <Banners />
      </div>
      <div className="footer">
        <Link className="button" icon={faBolt} iconAlign="right" href="/superpower/results/actions" >
          Take Action
        </Link>

        <Button className="button" icon={faPrint} iconAlign="right" onClick={() => { window.print() }} >
          Print results
        </Button>
      </div >
    </div >
  );
}

export default AssessmentResultPage;
