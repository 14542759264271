import { NavLink } from "react-router-dom";
import { trackLinkClick } from "@snowplow/browser-plugin-link-click-tracking";

import './index.css';

function TopMenu({ items, direction }) {

  const getClass = () => {
    const classNames = ['bccampus-navigation']
    classNames.push((direction === "vertical") ? 'vertical' : 'horizontal');

    return classNames.join(' ');
  };

  return (
    <nav aria-label='Site navigation' className={getClass()}>
      {items &&
        Object.entries(items).map(item => {
          return (
            <NavLink className="bccampus-navigation-item"
              key={item[1].to}
              to={item[1].to}
              exact={item[1].exactMatch}
              onMouseDown={(e) => { if (e.button < 2) trackLinkClick({ targetUrl: item[1].to }) }}
            >
              {item[1].icon}
              {item[1].label}
            </NavLink>
          );
        })
      }
    </nav>
  )
}

export default TopMenu;
