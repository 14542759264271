/**
* Source: https://gist.github.com/sphvn/dcdf9d683458f879f593?permalink_comment_id=3752985#gistcomment-3752985
*/
const traverseInternal = (object, fn) => {
    Object.entries(object).forEach(([key, value]) => {
        fn.apply(this, [object, key, value]);
        if (value !== null && typeof value === "object") {
            traverseInternal(value, fn);
        }
    });
};

const mapEntity = (entity) => {
    if (!entity)
        return null;

    if (!entity.attributes)
        entity.attributes = {};

    for (const key in entity) {
        if (key !== "attributes" && Object.hasOwnProperty.call(entity, key)) {
            entity.attributes[key] = entity[key];
        }
    }

    return entity.attributes;
}

const mapEntityResponse = (response) => {
    if (!response) return null;

    return mapEntity(response.data);
}

const mapResponseCollection = (collection) => {
    if (!collection || !Array.isArray(collection.data) || collection.data.length === 0)
        return [];

    return collection.data.map(entity => mapEntity(entity));
}

const mapStrapiV4Response = (response, key) => {
    if (!!response[key] && !!response[key].__typename) {
        if (response[key].__typename.endsWith("ResponseCollection")) {
            response[key] = mapResponseCollection(response[key]);
        }
        else if (response[key].__typename.endsWith("EntityResponse")) {
            response[key] = mapEntityResponse(response[key]);
        }
    }
};

const transformStrapiV4Response = (data) => traverseInternal(data, mapStrapiV4Response);

export default transformStrapiV4Response;