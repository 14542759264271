import BaseAtomFactory from '../../utils/BaseAtomFactory';
import repository from '../repositories/surveys';

const operations = {
  __name: "surveys",
  read: async () => {
    return [await repository.read()];
  }
}
const baseAtomFactory = new BaseAtomFactory(operations, null, { rereadOnMount: false });
export default baseAtomFactory;
