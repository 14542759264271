import Link from '../link';
import { ReactComponent as BCministryLogo } from './BC_PSEFS_H_RGB_pos.svg';
import { ReactComponent as BCministryLogoRev } from './BC_PSEFS_H_RGB_rev.svg';

function BCMinistryBadge({ label, reverse }) {

  return (
    <div>
      <Link target="_blank"
        href="https://www2.gov.bc.ca/gov/content/governments/organizational-structure/ministries-organizations/ministries/post-secondary-education-and-future-skills"
        aria-label="Funded by Ministry of Post-secondary Education and Future Skills"
      >
        {label &&
          <div>{label === true ? "Funded by:" : label}</div>
        }
        {reverse !== true
          ? <BCministryLogo
            alt="Ministry of Post-secondary Education and Future Skills logo"
            role="presentation"
          />
          : <BCministryLogoRev
            alt="Ministry of Post-secondary Education and Future Skills logo"
            role="presentation"
          />
        }
      </Link>
    </div>
  );
}

export default BCMinistryBadge;
