function ResultCard({ title, text, imageUrl }) {

  return (
    <div className="category-result-card">
      <div className="img-wrapper">
        <img
          src={imageUrl}
          alt={`${title} icon`}
          role="presentation"
        />
      </div>
      <div className="title">{title}</div>
      <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}

export default ResultCard;
