import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import Image from '../image';

import './index.css';

const Button = ({ children, type = "default", htmlType = "button", role = "button", secondary, noShadow, description, icon, image, iconAlign = "right", className, keyBindings, ...restProps }) => {

  const classNames = useMemo(() => {
    const classNames = [className ?? 'bccampus-button', type];

    if (secondary !== true) classNames.push('primary');
    if (noShadow === true) classNames.push('no-shadow');
    else classNames.push('secondary');

    return classNames.join(' ');
  }, [type, secondary, noShadow, className]);

  const handleKeys = (e) => {
    if (!e.code || !keyBindings || typeof keyBindings !== "object") return;

    const handler = keyBindings[e.code];
    if (typeof handler === "function") {
      e.preventDefault();
      handler();
    }
  }

  const getProps = () => (
    {
      ...restProps,
      onKeyDown: keyBindings ? handleKeys : undefined,
      className: classNames,
      children:
        <>
          {(iconAlign === "right" && type !== "icon") && <span>{children}</span>}
          {
            icon &&
            <FontAwesomeIcon icon={icon} className={`bccampus-button-icon align-${iconAlign}`} />
          }
          {
            image &&
            <Image icon data={image} className={`bccampus-button-icon align-${iconAlign}`} size={type === "icon" ? "2x" : undefined} />
          }
          {(iconAlign === "left" && type !== "icon") && <span>{children}</span>}
          {description &&
            <span className="description">{description}</span>
          }
        </>

    }
  )

  if (role === "button") return <button type={htmlType} {...getProps()} />;
  else return <span role={role} tabIndex={0} {...getProps()} />;
};

export default Button;
