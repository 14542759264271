import { Col, Row, Typography } from 'antd';
import Horizontal from '../horizontal';
import Image from '../image';
import Link from '../link';
import defaultCoverImage from './default-cover-image.jpg'

import './index.css';

function ListItem({ itemId, title, subtitle, extra, meta, href, coverImage, data, ...restProps }) {
  return (
    <Link {...restProps} type="card" className="bccampus-list-item" href={href}>
      <Horizontal gap="1em" style={{ width: "100%" }}>
        <Image alt="cover" src={coverImage ?? defaultCoverImage} className="bccampus-list-item-cover" bordered />
        <Row className="bccampus-list-item-body" gutter={[0, 0]} align="middle" >
          <Col xs={24} lg={!!meta ? 12 : 24} >
            <Row>
              <Col span={24}>
                <Typography.Paragraph className="bccampus-list-item-title" title={title} ellipsis={true}>
                  {title}
                </Typography.Paragraph>
              </Col>
              {!!subtitle &&
                <Col span={24}>
                  <Typography.Paragraph className="bccampus-list-item-subtitle" ellipsis={true}>
                    {subtitle}
                  </Typography.Paragraph>
                </Col>
              }
              {!!extra &&
                <Col span={24}>
                  <Typography.Paragraph className="bccampus-list-item-extra" id={itemId ? `bccampus-list-item-extra-${itemId}` : undefined} ellipsis={true}>
                    {extra}
                  </Typography.Paragraph>
                </Col>
              }
            </Row>
          </Col>
          {!!meta &&
            <Col xs={0} lg={12} className="bccampus-list-item-meta" id={itemId ? `bccampus-list-item-meta-${itemId}` : undefined}>
              {meta}
            </Col>
          }
        </Row>
      </Horizontal>
    </Link>
  )
}

export default ListItem;
