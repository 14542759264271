import { atom } from 'jotai';
import { atomWithDefault } from 'jotai/utils'

/**
 * Operations signature :
 * {
 *  operationName: (action, key) => return [newState, item]
 * }
 *
 * options:
 * {
 *  rereadOnMount: boolean, // true -> to read data from the source everytime the associated components mounted
 *  initialize: boolean, // true -> to read data from the source immediately after an associated component mounted
 * }
 *
 */
const BaseAtomFactory = function (operations, key, options) {
    const _options = { initialize: true, rereadOnMount: true, ...options };
    let _initialized = false;

    const shadowAtom = atomWithDefault(async () => {
        let data = null;
        if (_options.initialize === true) {
            const result = await operations.read(null, key);
            data = (Array.isArray(result)) ? ((result.length > 0) ? result[0] : null) : result;
        }

        return {
            running: false,
            success: null,
            error: null,
            action: null,
            data: data
        }
    });

    shadowAtom.onMount = (setAtom) => {
        return () => {
            setAtom(prev => ({
                ...prev,
                running: false,
                success: null,
                error: null,
                action: null
            }));
        }
    };

    const baseAtom = atom(
        (get) => get(shadowAtom),
        (get, set, action) => {
            const prevAction = get(shadowAtom);
            if (prevAction.running === true) return;

            const runAction = async (asyncActionFn) => {
                try {
                    set(shadowAtom, prev => ({
                        ...prev,
                        running: true,
                        success: null,
                        error: null,
                        action: action.type
                    }));

                    const [newState, item] = await asyncActionFn(action, key);
                    if (newState) {
                        let data = (typeof newState === 'function')
                            ? newState(prevAction.data)
                            : newState;
                        set(shadowAtom, prev => ({
                            ...prev,
                            data: data,
                            success: true,
                            running: false,
                            item: item
                        }));
                    }
                    else {
                        set(shadowAtom, prev => ({
                            ...prev,
                            success: true,
                            running: false,
                            item: item
                        }));
                    }

                }
                catch (error) {
                    set(shadowAtom, prev => ({
                        ...prev,
                        success: false,
                        running: false,
                        error: error
                    }));
                }
                finally {
                    if (action.callback instanceof Function) {
                        action.callback(get(shadowAtom));
                    }
                }
            }

            if (operations[action.type] instanceof Function) {
                runAction(operations[action.type]);
            }
            else {
                set(shadowAtom, prev => ({
                    ...prev,
                    success: false,
                    running: false,
                    error: { message: "Invalid action type!" }
                }));
            }
        }
    );

    baseAtom.onMount = (setAtom) => {
        if (_options.rereadOnMount === true && _initialized === true) {
            setAtom({ type: 'read' });
        }
        _initialized = true;
    };

    return baseAtom
};

export default BaseAtomFactory;
