import ListItem from "./index";

function LearninResourceListItem({ data, ...props }) {
  return (
    <ListItem
      {...props}
      itemId={data.id}
      type="card" className="bccampus-material-info-card" href={`/learning-resources/${data.slug}/`}
      aria-label={`${data.type}: ${data.title}${data.course ? ` for ${data.course}` : ""}`}
      coverImage={data.coverImage}
      title={data.title}
      extra={data.course ? `For ${data.course}` : undefined}
      meta={<span>{data.type}</span>}
    />
  )
}

export default LearninResourceListItem;
