import { Helmet } from "react-helmet";
import { IS_SNAP, IS_PRODUCTION, getEnvVar } from "../utils/envVar";

const HOTJAR_ID = getEnvVar('REACT_APP_HOTJAR_ID');

const HotJar = () => {
  if (!IS_PRODUCTION || IS_SNAP || !HOTJAR_ID) return null;

  return (
    <Helmet>
      <script>
        {`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
      </script>
    </Helmet>
  );
};

export default HotJar;
