import BCMinistryBadge from '../../../components/bc-ministry-badge';
import { ReactComponent as BCcampusLogo } from '../../../components/bccampus-badge/bccampus-logo.svg'
import { Row, Col } from "antd";

function Banners() {

  return (
    <div className="banners section">
      <div className="heading">Brought to you by</div>
      <Row gutter={16} justify="center" align="middle">
        <Col xs={24} lg={12}>
          {/* <img src={BCGovLogo} role="presentation" alt="Government of BC logo" /> */}
          <BCMinistryBadge reverse />
        </Col>
        <Col xs={24} lg={12} style={{ padding: "0em 2em" }}>
          <BCcampusLogo role="presentation" alt="BCcampus logo" />
        </Col>
      </Row>
    </div>
  );
}

export default Banners;
