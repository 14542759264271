import { Row, Col } from 'antd';
import { useAtomValue } from 'jotai/utils';

import FilterBox from './filter-box';

import { searchFacetsAtom } from '../../hooks/useSearch'
import { useMemo } from 'react';

import subjectsAtom from '../../data/atoms/subjects';

function FacetedSearchSubjects({ sortByHits, ...restProps }) {

  const searchFacets = useAtomValue(searchFacetsAtom);
  const subjects = useAtomValue(subjectsAtom);

  const subjectsFilters = useMemo(() => {
    if (searchFacets.subjects && subjects.data) {
      const filters = [];

      for (const subject of subjects.data) {
        if (searchFacets.subjects[subject.label]) {
          filters.push({
            ...subject,
            hits: searchFacets.subjects[subject.label],
          });
        }
      }

      if (sortByHits === true) filters.sort((a, b) => { return b.hits - a.hits });
      return filters
    }

    return [];
  }, [subjects, searchFacets, sortByHits]);

  return (
    <Row gutter={[14, 14]} {...restProps}>
      {
        subjectsFilters.map(subject => (
          <Col key={subject.label} xs={12} sm={8} md={6} xl={4}>
            <FilterBox
              href={`/subjects/${subject.slug}/`}
              label={subject.label}
              image={subject.image}
              color={subject.color}
              extra={`${subject.hits} ${subject.hits > 1 ? 'results' : 'result'}`}
              aria-label={`Show ${subject.hits} ${subject.hits > 1 ? 'results' : 'result'} for ${subject.label}`} />
          </Col>
        ))
      }
    </Row>
  )
}
export default FacetedSearchSubjects;
