import { Helmet } from "react-helmet";
import { IS_SNAP, IS_PRODUCTION, getEnvVar } from "../utils/envVar";

const GOOGLE_SITE_VERIFICATION_KEY = getEnvVar('REACT_APP_GOOGLE_SITE_VERIFICATION_KEY');

const GoogleSiteVerification = () => {
  if (!IS_PRODUCTION || IS_SNAP || !GOOGLE_SITE_VERIFICATION_KEY) {
    console.log("GoogleSiteVerification!!!", IS_PRODUCTION, IS_SNAP, GOOGLE_SITE_VERIFICATION_KEY);
    return null;
  }

  return (
    <Helmet>
      <meta name="google-site-verification"
        content={GOOGLE_SITE_VERIFICATION_KEY} />
    </Helmet>
  )
};

export default GoogleSiteVerification;
