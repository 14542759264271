import apiRequest from '../../utils/bccampusApiRequest.js';
import surveys from '../configs/Surveys.js';


const read = async (id) => {
  if (id) {
    return await getById(id);
  } else {
    return await getAll();
  }
}

const getById = async (id) => {
  const result = await apiRequest(surveys.get(id));
  return result.data;
}

const getAll = async () => {
  const result = await apiRequest(surveys.getAll());
  return result.data;
}

const repository = { read };

export { read }
export default repository
