import { newTracker, enableActivityTracking, trackPageView } from '@snowplow/browser-tracker';
import { enableLinkClickTracking, LinkClickTrackingPlugin } from "@snowplow/browser-plugin-link-click-tracking"
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getEnvVar, IS_SNAP, IS_PRODUCTION } from '../utils/envVar';

const SNOWPLOW_ENDPOINT = getEnvVar('REACT_APP_SNOWPLOW_ENDPOINT');
const SNOWPLOW_APP_ID = getEnvVar('REACT_APP_SNOWPLOW_APP_ID');

let tracker = null;

export function Snowplow() {
  if (!IS_PRODUCTION || IS_SNAP || !SNOWPLOW_ENDPOINT || !SNOWPLOW_APP_ID) return null;

  return <SnowplowTracker />;
};


const SnowplowTracker = () => {
  const location = useLocation();

  if (tracker === null) {
    tracker = newTracker("bcdl", SNOWPLOW_ENDPOINT, {
      appId: SNOWPLOW_APP_ID,
      cookieLifetime: 47347200, // seconds => 548 days
      platform: "web",
      contexts: {
        webPage: true,
        performanceTiming: true,
      },
      plugins: [LinkClickTrackingPlugin()],
    });

    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 30,
    });

    enableLinkClickTracking({ pseudoClicks: true });
  }

  useEffect(() => {
    if (tracker !== null) trackPageView()
  }, [location]);

  return null;
};
