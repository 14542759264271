import Axios from "axios";
import { stringify } from "qs";

const endpoint = process.env.REACT_APP_OOC_RESTAPI_ENDPOINT;

function authRequestInterceptor(config) {
  config.headers.Accept = "application/json";
  return config;
}

const axios = Axios.create({
  baseURL: endpoint,
  timeout: 10000,
  paramsSerializer: (params) => stringify(params, { encodeValuesOnly: true }),
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export { axios };
