import useUserSettings from '../../../hooks/useUserSettings';

import CategoriesPage from './categories';
import CategoryResultPage from './category-result';
import QuestionsPage from './questions';

function SessionPage({ code }) {
  const { userSettings } = useUserSettings();

  if (!userSettings.competencyInProgress) {
    return <CategoriesPage sessionCode={code} />;
  }
  else {
    if (userSettings.competencyInProgress.page >= 0)
      return <QuestionsPage />;
    else
      return <CategoryResultPage />;
  }

}

export default SessionPage;
