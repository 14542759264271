import ListItem from "./index";

function CourseListItem({ data, ...props }) {
  return (
    <ListItem
      {...props}
      itemId={data.id}
      href={`/course-packs/${data.slug}/`}
      aria-label={`${data.name} course pack`}
      aria-describedby={`bccampus-list-item-extra-${data.id}`}
      coverImage={data.coverImage}
      title={data.name}
      extra={data.educationalLevels?.map(level => level).join(", ")}
      meta={data.subjects?.map(subject => <span key={subject} className="bccampus-tag style-2">{subject}</span>)}
    />
  )
}

export default CourseListItem;
