
const IS_SNAP = navigator.userAgent === 'ReactSnap';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const getEnvVar = (envVarName) => {
  const value = process.env[envVarName];

  if (!value || value === "NULL" || value === "UNDEFINED") return null;

  return value;
};


export { getEnvVar, IS_SNAP, IS_PRODUCTION };
