const sumObjectValuesByKey = (...inputs) => {
    const sum = {};

    for (const input of inputs) {
        if (typeof input === 'object' && input !== null) {
            for (const key in input) {
                sum[key] = input[key] + (sum[key] || 0);
            }
        }
    }
    return sum;
};

export default sumObjectValuesByKey;