import { useEffect, useState } from "react";
import Slider from "react-slider";

function Scale({ marks, value, onChange, readonly, answerKey, result, showExplanation = false, explanation }) {
  const [valueState, setValueState] = useState(0);

  const handleChange = (choiceIndex) => {
    setValueState(choiceIndex);

    if (typeof onChange === "function") {
      onChange(marks[choiceIndex].id);
    }
  }

  useEffect(() => {
    if (!value && typeof onChange === "function") {
      handleChange(Math.floor(marks.length / 2));
    }
    else {
      setValueState(marks.findIndex(c => c.id === value));
    }
  },
    // eslint-disable-next-line
    [marks]
  );

  return showExplanation !== true ? (
    <div className="scale">
      <Slider
        orientation="vertical"
        className="slider"
        trackClassName="slider_track"
        markClassName="slider_mark"
        thumbClassName="slider_thumb"
        value={valueState}
        ariaLabel="Selection"
        ariaValuetext={state => marks[state.value]?.label ?? `Item ${state.value + 1}`}
        marks={1}
        invert
        disabled={readonly}
        renderMark={(props) => {
          if (valueState === props.key)
            props.className += " selected"

          if (Array.isArray(answerKey))
            if (answerKey.includes(marks[props.key].id))
              props.className += " correct"
            else
              props.className += " incorrect"

          return (
            <span
              {...props}
            >
              {marks[props.key].label}
            </span>
          );
        }}
        min={0}
        max={marks.length - 1}
        onChange={handleChange}

      />
    </div>
  ) : (
    <div>
      {explanation &&
        <div className="paragraph" dangerouslySetInnerHTML={{ __html: explanation }} />
      }
    </div>
  );
}

export default Scale;
