const actions = {

  getByCode: (code) => {
    return {
      method: "get",
      url: `/survey-sessions/${code}`,
    };
  },

  create: () => {
    return {
      method: "post",
      url: "/survey-sessions",
      data: { code: "________" }
    };
  },

  emailCode: (sessionCode, email) => {
    return {
      method: "post",
      url: "/survey-sessions/email-code",
      data: { sessionCode, email, site: "BCDL" }
    };
  },

  emailResults: (sessionCode, email) => {
    return {
      method: "post",
      url: "/survey-sessions/email-results",
      data: { sessionCode, email, site: "BCDL" }
    };
  },
};

export default actions;
