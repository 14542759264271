import { Drawer } from 'antd';
import { Suspense, useMemo, useRef } from 'react';
import Horizontal from '../horizontal';
import Button from '../button';
import FocusTrap from '../focus-trap';
import { Label } from '../typography';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import './index.css';
import { useCallback } from 'react';
import { atom, useAtom } from 'jotai';
import Fallback from '../suspense-fallback';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const slidingModalPropsAtom = atom({
  children: null,
  className: null,
  openedBy: null,
  onClose: null,
  title: null,
  open: false,
  width: 480,
});

const SlidingModalInstance = ({ id, ...restProps }) => {
  const screens = useBreakpoint();
  const focusTrapRef = useRef(null);
  const [slidingModalProps, setSlidingModalProps] = useAtom(slidingModalPropsAtom);

  const classNames = useMemo(() => {
    const classNames = ['bccampus-drawer']
    if (slidingModalProps.className) classNames.push(slidingModalProps.className)
    return classNames.join(' ');
  }, [slidingModalProps.className]);

  const handleClose = useCallback(
    () => {
      const openedByEl = document.querySelector(slidingModalProps.openedBy);
      if (openedByEl) openedByEl.focus();

      if (typeof slidingModalProps.onClose === 'function') slidingModalProps.onClose();
      else setSlidingModalProps(prev => ({ ...prev, open: false }));

    },
    [slidingModalProps, setSlidingModalProps],
  );

  return (
    <div id={id ?? slidingModalProps.id}>
      <Drawer
        className={classNames}
        onClose={handleClose}
        maskClosable={false}
        closable={false}
        keyboard={false}
        open={slidingModalProps.open}
        width={screens.md ? slidingModalProps.width : "100%"}
        {...restProps}
        placement="right"
        autoFocus={false}
        destroyOnClose
        afterOpenChange={(isOpen) => {
          if (isOpen) focusTrapRef?.current.focus();
          else handleClose();
        }}
      >
        <FocusTrap ref={focusTrapRef}>
          <Suspense fallback={<Fallback />}>

            <Horizontal style={{ marginBottom: "1em" }}>
              <Label role="heading">
                {slidingModalProps.title}
              </Label>
              <Horizontal.Spacer />
              <Button
                type="icon"
                secondary icon={faTimes} onClick={handleClose}
                aria-label={`Close ${slidingModalProps.title ?? ""} dialog`}
              />
            </Horizontal>
            {slidingModalProps.children}
          </Suspense>
        </FocusTrap>
      </Drawer>
    </div>
  );
};

const SlidingModal = (props) => {
  return <SlidingModalInstance {...props} id="bccampus-sliding-modal-instance" />
}

export { SlidingModal, slidingModalPropsAtom };
export default SlidingModal;
