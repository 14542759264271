import BaseAtomFactory from '../../utils/BaseAtomFactory';
import subjectsRepository from '../repositories/subjects';

const operations = {
    __name: "subjects",
    read: async () => {
        return [await subjectsRepository.read()];
    }
}
const subjectsAtom = new BaseAtomFactory(operations, null, { rereadOnMount: false });
export default subjectsAtom;
