import { atom, useAtom } from 'jotai';
import { stringify } from 'query-string';
import { useUpdateAtom } from 'jotai/utils';
import { useHistory } from 'react-router';

import searchClient from '../utils/MeiliSearch';
import { useEffect, useState } from 'react';

const searchQueryAtom = atom("");
const searchFilterAtom = atom({});

const searchResultsDefaults = {
  query: '',
  totalHits: 0,
  results: { courses: [], learningResources: [] }
};
const searchResultsAtom = atom(searchResultsDefaults);

const searchFacetsAtom = atom({});

const searchSettingsDefaults = {
  viewAllCourses: false,
  viewAllTextbooks: false,
  viewAllResources: false
};
const searchSettingsAtom = atom(searchSettingsDefaults);

function useSearch() {
  const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);
  const [searchSettings, setSearchSettings] = useAtom(searchSettingsAtom);
  const [searchFilter, setSearchFilter] = useAtom(searchFilterAtom);

  const setSearchResults = useUpdateAtom(searchResultsAtom);
  const setSearchFacets = useUpdateAtom(searchFacetsAtom);

  const history = useHistory();

  const [isHealthy, setIsHealthy] = useState(null);

  useEffect(() => {
    const checkSearchEngineHealth = async () => {
      setIsHealthy(await searchClient.isHealthy());
    }

    checkSearchEngineHealth();
  }, []);

  const getSearchParams = (query, filter, settings) => {
    const isSubjectPage = history.location.pathname.startsWith('/subjects/');
    const subjectFilterCount = filter.subjects?.length;
    return stringify({
      q: query,
      subjects: (!isSubjectPage && subjectFilterCount > 0) || (isSubjectPage && subjectFilterCount > 1)
        ? filter.subjects
        : undefined,

      type: filter.type?.length > 0 ? filter.type : undefined,
      educationalLevels: filter.educationalLevels?.length > 0 ? filter.educationalLevels : undefined,
      viewAllCourses: settings.viewAllCourses || undefined,
      viewAllTextbooks: settings.viewAllTextbooks || undefined,
      viewAllResources: settings.viewAllResources || undefined,
    }, { arrayFormat: "bracket-separator", sort: false, skipEmptyString: true, skipNull: true });
  };

  const getFilterExpression = (filter) => {
    const filterExpression = [];
    for (const key in filter) {
      if (filter[key]?.length) {
        if (!Array.isArray(filter[key])) filter[key] = [filter[key]];
        filterExpression.push(...filter[key].map(filter => `${key} = '${filter}'`));
      }
    }

    return filterExpression;
  }

  const getSettings = (settings) => ({
    coursesLimit: settings?.viewAllCourses === true ? 1000 : 4,
    textbooksLimit: settings?.viewAllTextbooks === true ? 1000 : 6,
    learningResourcesLimit: settings?.viewAllResources === true ? 1000 : 6,
  });

  const refreshFacets = async () => {
    setSearchFacets(await searchClient.refreshFacets());
  };

  const search = async (query, filter, settings, isEffect, updateFilter, updateSettings) => {

    setSearchQuery(query);
    if (updateFilter !== false) setSearchFilter(filter || {});
    if (updateSettings !== false) setSearchSettings(settings);

    if (isEffect !== true) history.push(`?${getSearchParams(query, filter, settings)}`);

    setSearchResults(await searchClient.search(query, getFilterExpression(filter), getSettings(settings)));
  };

  const clearResults = () => {
    setSearchQuery("");
    setSearchFilter({});
    setSearchResults(searchResultsDefaults);
  }

  const setSearchParams = (params) => {
    history.replace(`?${getSearchParams(
      params.query !== undefined ? params.query : searchQuery,
      params.filter !== undefined ? params.filter : searchFilter,
      params.settings !== undefined ? params.settings : searchSettings
    )}`);
  }

  const setSettings = (settings) => {
    setSearchParams({ settings });
  }

  const setFilter = (filter) => {
    setSearchParams({ filter });
  }

  const setQuery = (query) => {
    setSearchParams({ query });
  }

  return {
    refreshFacets,
    search,
    clearResults,
    setSearchParams,
    setQuery,
    setFilter,
    setSettings,
    isHealthy
  }
}


export { useSearch, searchQueryAtom, searchResultsAtom, searchFilterAtom, searchFacetsAtom, searchSettingsAtom };
