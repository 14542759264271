import { Provider } from 'jotai';
import { BrowserRouter as Router } from "react-router-dom";

import App from './views/App';

import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<Provider><Router><App /></Router></Provider>);

