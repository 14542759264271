import { faArrowLeft, faBolt } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import Link from "../../../components/link";
import Banners from "../components/banners";
import Header from "../components/header";

function TakeActionsPage() {
  const history = useHistory();
  const sectionRef = useRef();

  sectionRef.current?.focus();

  return (
    <div className="default">
      <Header
        left={{
          icon: faArrowLeft,
          onClick: () => { history.goBack() },
        }}
      />
      <div className="content">
        <div className="section centered" ref={sectionRef} tabIndex={-1}>
          <div className="heading" style={{ marginBottom: "1em" }}>Take Actions</div>
          <div className="action">
            <p>Use your powers for good by incorporating those skills in your daily coursework.</p>
            <Link className="button" type="button" href="/teach-it">Start Teaching</Link>
          </div>
          <div className="action">
            <p>Tap into your potential by levelling up your own digital literacy.</p>
            <Link className="button" type="button" icon={faBolt} iconAlign="right" href="/learn-it">Power Up</Link>
          </div>
        </div>
        <Banners />
      </div>
    </div>
  );
}

export default TakeActionsPage;
