import { Col, Row, Typography } from 'antd';
import defaultCoverImage from './default-cover-image.jpg'
import Link from '../../link';
import Image from '../../image';

import '../index.css';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileLines, faUniversalAccess, faCheck } from '@fortawesome/free-solid-svg-icons';

function TextbookInfoCard({ data }) {
  return (
    <Link
      type="card" className="bccampus-textbook-info-card" href={`/textbooks/${data.slug}/`}
      aria-label={`${data.title}${data.subtitle ? `: ${data.subtitle}` : ""}${data.edition ? `, ${data.edition}` : ""} textbook`}
      aria-describedby={`bccampus-textbook-publisher-${data.id} bccampus-textbook-meta-${data.id}`}
    >
      <Image alt="cover"
        src={data.coverImage || defaultCoverImage}
        className="bccampus-textbook-info-card-cover"
      />
      <div style={{
        marginLeft: "9em",
        width: "calc(100% - 9em)",
        height: "100%",
        position: "relative"
      }} >
        <div className="bccampus-textbook-info-card-tag"  >
          {data.type}
        </div>
        <div className="bccampus-textbook-info-card-body" >
          <Typography.Paragraph
            className="bccampus-card-title"
            ellipsis={{ rows: 2 }}
            title={data.title}
          >
            {data.title}
          </Typography.Paragraph>
          {!!data.subtitle &&
            <Typography.Paragraph
              className="bccampus-card-subtitle"
              ellipsis={{ suffix: " " }}
            >
              {data.subtitle}
            </Typography.Paragraph>
          }
          <Typography.Text
            className="bccampus-card-extra"
            ellipsis={{ suffix: " " }}>
            {!!data.edition &&
              data.edition
            }
            {!!data.publisher && !!data.edition &&
              " | "
            }
            {!!data.publisher &&
              <span id={`bccampus-textbook-publisher-${data.id}`} aria-label={`, published by ${data.publisher}`}>{data.publisher}</span>
            }
          </Typography.Text>
        </div>
        <div
          id={`bccampus-textbook-meta-${data.id}`}
          className="bccampus-card-meta bccampus-textbook-info-card-meta"
        >
          <Row gutter={[4, 4]}>
            {data.isAdopted &&
              <>
                <Col xs={6} lg={0} style={{ textAlign: "center" }}>
                  <FontAwesomeIcon icon={faUser} className="bccampus-feature-label" style={{ fontSize: "1.5em" }} />
                </Col>
                <Col xs={0} lg={11}>
                  <span
                    id={`bccampus-textbook-adopted-${data.id}`}
                    className="bccampus-feature-label"
                    aria-label=", adopted"
                  >
                    <FontAwesomeIcon icon={faUser} />Adopted
                  </span>
                </Col>
              </>
            }
            {data.hasSupplementaryMaterials &&
              <>
                <Col xs={6} lg={0} style={{ textAlign: "center" }}>
                  <FontAwesomeIcon icon={faFileLines} className="bccampus-feature-label" style={{ fontSize: "1.5em" }} />
                </Col>
                <Col xs={0} lg={13}>
                  <span
                    id={`bccampus-textbook-supplemental-${data.id}`}
                    className="bccampus-feature-label"
                    aria-label=", has supplemental files"
                  >
                    <FontAwesomeIcon icon={faFileLines} />Supplemental
                  </span>
                </Col>
              </>
            }
            {data.isAccessible &&
              <>
                <Col xs={6} lg={0} style={{ textAlign: "center" }}>
                  <FontAwesomeIcon icon={faUniversalAccess} className="bccampus-feature-label" style={{ fontSize: "1.5em" }} />
                </Col>
                <Col xs={0} lg={11}>
                  <span
                    id={`bccampus-textbook-accessible-${data.id}`}
                    className="bccampus-feature-label"
                    aria-label=", accessible"
                  >
                    <FontAwesomeIcon icon={faUniversalAccess} />Accessible
                  </span>
                </Col>
              </>
            }
            {data.isReviewed &&
              <>
                <Col xs={6} lg={0} style={{ textAlign: "center" }}>
                  <FontAwesomeIcon icon={faCheck} className="bccampus-feature-label" style={{ fontSize: "1.5em" }} />
                </Col>
                <Col xs={0} lg={13}>
                  <span
                    id={`bccampus-textbook-reviewed-${data.id}`}
                    className="bccampus-feature-label"
                    aria-label=", reviewed"
                  >
                    <FontAwesomeIcon icon={faCheck} />Reviewed
                  </span>
                </Col>
              </>
            }
          </Row>
        </div>
      </div>
    </Link >
  )
}

export default TextbookInfoCard;
