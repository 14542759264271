const actions = {
  getAll: (params = {}) => {

    const filters = {
      ...params.filters,
      scheme: { code: "subject" },
    }

    return {
      method: "get",
      url: "/concepts",
      params: {
        filters,
        populate: {
          image: true,
        },
      }
    };
  },

  get: (id) => {

    const filters = {
      scheme: { code: "subject" },
    }

    return {
      method: "get",
      url: `/concepts/${id}`,
      params: {
        filters,
        populate: {
          image: true,
        },
      }
    };
  }
};

export default actions;
