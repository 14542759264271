import apiRequest from '../../utils/bccampusApiRequest.js';
import subjects from '../configs/Subjects.js';

const read = async (id) => {
  if (id) {
    return await getById(id);
  } else {
    return await getAll();
  }
}

const getById = async (id) => {
  const result = await apiRequest(subjects.get(id));
  return result.data;
}

const getAll = async () => {
  const result = await apiRequest(subjects.getAll());
  return result.data;
}

const readBySlug = async (slug) => {
  const result = await apiRequest(subjects.getAll({
    filters: { code: slug },
    pagination: { pageSize: 1 }
  }));
  return result.data;
}

const readByParentSlug = async (slug) => {
  const result = await apiRequest(subjects.getAll({
    filters: { broader: { code: slug } }
  }));
  return result.data;
}

const subjectsRepository = { read, readBySlug, readByParentSlug };

export { read }
export default subjectsRepository
