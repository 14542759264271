const actions = {
  getBySession: (code) => {
    return {
      method: "get",
      url: `/survey-responses/${code}`,
    };
  },

  create: (data) => {
    return {
      method: "post",
      url: `/survey-responses`,
      data,
    };
  }
};

export default actions;
