import apiRequest from '../../utils/bccampusApiRequest';
import responses from '../configs/SurveyResponses.js';

const read = async (code) => {
  if (code) {
    return await getBySession(code);
  }
}

const getBySession = async (code) => {

  const result = await apiRequest(responses.getBySession(code));
  return result.data;
}

const create = async (data) => {
  const result = await apiRequest(responses.create(data));

  return result.data;
}

const repository = { read, create };

export { read, create }
export default repository
