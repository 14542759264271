import { faCheck, faFileLines, faUniversalAccess, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItem from "./index";

function TextbookListItem({ data, ...props }) {
  return (
    <ListItem
      {...props}
      itemId={data.id}
      href={`/textbooks/${data.slug}/`}
      aria-label={`${data.title}${data.subtitle ? `: ${data.subtitle}` : ""}${data.edition ? `, ${data.edition}` : ""} textbook`}
      aria-describedby={`bccampus-textbook-publisher-${data.id} bccampus-list-item-meta-${data.id}`}
      coverImage={data.coverImage}
      title={data.title}
      subtitle={data.subtitle}
      extra={
        <>
          {!!data.edition && data.edition}
          {!!data.publisher && !!data.edition && " | "}
          {!!data.publisher &&
            <span id={`bccampus-textbook-publisher-${data.id}`} aria-label={`, published by ${data.publisher}`}>{data.publisher}</span>
          }
        </>
      }
      meta={
        <>
          {data.isAdopted && <span className="bccampus-tag" aria-label=", adopted"><FontAwesomeIcon icon={faUser} />Adopted</span>}
          {data.hasSupplementaryMaterials && <span className="bccampus-tag" aria-label=", has supplemental files"><FontAwesomeIcon icon={faFileLines} />Supplemental</span>}
          {data.isAccessible && <span className="bccampus-tag" aria-label=", accessible"><FontAwesomeIcon icon={faUniversalAccess} />Accessible</span>}
          {data.isReviewed && <span className="bccampus-tag" aria-label=", reviewed"><FontAwesomeIcon icon={faCheck} />Reviewed</span>}
        </>
      }
    />
  )
}

export default TextbookListItem;
