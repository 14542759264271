import { Row, Col, Result } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { Paragraph, Text } from '../typography';
import Link from '../link';

function NoSearchResults({ id, style }) {

  return (
    <Row>
      <Col xs={{ span: 24, offset: 0 }} xl={{ span: 16, offset: 4 }}>
        <Result id={id} style={style}
          icon={
            <FontAwesomeIcon
              icon={faFolderOpen}
              size="8x"
              style={{
                color: "rgba(67, 66, 66, 0.5)"
              }}
            />
          }
          title="No Results Found"
          subTitle={
            <Text>
              Search all materials using B.C. course codes, subjects, and keywords
            </Text>
          }
          extra={
            <Paragraph style={{ backgroundColor: "var(--background-color-alt)", padding: "1.5em" }}>
              Looking for something we don’t have? BCcampus is a highly curated collection of materials based on strict criteria,
              but there are millions of other open resources out there. <Link href="https://oasis.geneseo.edu/" icon={faExternalLink} >Search beyond BCcampus using OASIS</Link>
            </Paragraph>
          }
        />
      </Col>
    </Row>
  )
}
export default NoSearchResults;
