import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';


const userSettingsAtom = atom({});

const mergeObjects = (t, s) => {
  const merged = { ...t };
  for (const key in s) {
    if (Object.hasOwnProperty.call(s, key)) {
      if (!!t[key]) {
        if (Array.isArray(t[key])) merged[key] = [...t[key], ...s[key]];
        else merged[key] = { ...t[key], ...s[key] };
      }
      else {
        merged[key] = s[key];
      }
    }
  }
  return merged;
};

const TTL = 60;

/**
 *
 * @param {Object} defaultSettings
 * @returns {{ userSettings:Object, saveUserSettings:Function, resetUserSettings:Function, isUserSettingsAlive:Function }}
 */
const useUserSettings = (defaultSettings = {}) => {
  const [userSettings, setUserSettings] = useAtom(userSettingsAtom);

  useEffect(() => {
    let savedUserSession;
    try {
      savedUserSession = JSON.parse(window.localStorage.getItem("userSettings"));
    } catch {
      savedUserSession = defaultSettings;
    }

    if (savedUserSession) {
      setUserSettings(savedUserSession);
    }
    else {
      saveUserSettings(defaultSettings);
    }
  },
    //eslint-disable-next-line
    []
  );

  const saveUserSettings = (newUserSettings, mergeIfExist = false) => {
    if (newUserSettings !== null && typeof newUserSettings === "object") {
      if (mergeIfExist)
        newUserSettings = mergeObjects(userSettings, newUserSettings);
      else
        newUserSettings = { ...userSettings, ...newUserSettings };

      newUserSettings._lastUpdatedAt = Date.now();
      window.localStorage.setItem("userSettings", JSON.stringify(newUserSettings));
      setUserSettings(newUserSettings);
    }
  };

  const resetUserSettings = () => {
    window.localStorage.setItem("userSettings", JSON.stringify(defaultSettings));
    setUserSettings(defaultSettings);
  };

  const isUserSettingsAlive = () => {
    return typeof userSettings._lastUpdatedAt === "number" && (Date.now() - userSettings._lastUpdatedAt) < (TTL * 1000);
  };

  return { userSettings, saveUserSettings, resetUserSettings, isUserSettingsAlive };

};

export default useUserSettings;
