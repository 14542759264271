import { useEffect } from 'react';

const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

function useRecaptcha() {

    useEffect(() => {
        const loadScriptByURL = (id, url) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                document.body.appendChild(script);
            }

        }
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`);
    }, []);


    return {
        execute: (action, fn) => {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: action }).then(function (token) {
                    if (fn) fn(token);
                });
            });
        }
    }
}

export default useRecaptcha;