
import { Label, Text } from "./typography";
import SpinnerIcon from "./spinner-icon";

const Fallback = ({ title, content, icon, iconSize = "5x", iconSpin = true, spinDuration = "3s" }) => (
  <>
    <div></div>
    <div style={{
      justifySelf: "baseline",
      textAlign: "center",
      display: "flex",
      gap: "2em",
      alignContent: "center",
      justifyContent: "center",
      flexFlow: "column",
      padding: "2em",
    }}>
      <SpinnerIcon icon={icon} iconSize={iconSize} iconSpin={iconSpin} spinDuration={spinDuration} />
      <Label>
        {title ?? "Content loading..."}
      </Label>
      {content && <Text>{content}</Text>}
    </div>
  </>
);

export default Fallback;
