import { Col, Row } from 'antd';

import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import SurveyAtomFactory from '../../../data/atoms/surveys';
import useUserSettings from '../../../hooks/useUserSettings';
import Button from '../../../components/button';

import ribbonImg from '../assets/ribbon.png';
import Link from '../../../components/link';
import NotFound from '../components/not-found';
import Header from '../components/header';
import { DialogAtom, DialogTypes } from '../components/dialog';
import { faMask, faRotateLeft } from '@fortawesome/free-solid-svg-icons';

function CategoriesPage({ sessionCode }) {
  const { userSettings, saveUserSettings, resetUserSettings } = useUserSettings();
  const surveys = useAtomValue(SurveyAtomFactory);
  const setDialogState = useUpdateAtom(DialogAtom);

  const allDone = surveys.data.every(survey => userSettings.competenciesDone?.includes(survey.category));

  const onSelectCategory = (entry) => {
    saveUserSettings({ competencyInProgress: { key: entry.category, id: entry.id, page: 0 } });
  }

  const onSelectDoneCategory = (entry) => {
    const response = userSettings.responses?.find(r => r.survey.id === entry.id);
    if (response)
      saveUserSettings({
        competencyInProgress: {
          key: entry.category,
          id: entry.id,
          done: true,
          page: -1,
          score: response.score,
          answers: response.answers,
          answerKeys: response.survey.questions.reduce((keys, question) => {
            if (question.__component === "question.multiple-choice")
              keys[question.__component] = {
                ...keys[question.__component],
                [question.id]: question.choices.map(c => c.id)
              };

            return keys;
          }, {}),
        }
      });
  }

  if (surveys.error !== true)
    return (
      <div className="default">
        <Header
          right={{
            icon: faRotateLeft,
            label: "Start over",
            onClick: () => {
              setDialogState({
                open: true,
                type: DialogTypes.confirm,
                title: "Start Over",
                message: "Are you sure?",
                onPositiveAction: resetUserSettings,
              });
            }
          }}
        />
        <div className="content categories">
          <div className="section">
            <div className="heading">Start searching</div>
            <Row gutter={[24, 24]} style={{ marginTop: "2em" }}>
              {
                surveys.data.map(survey => (
                  <Col xs={12} md={8} lg={6} key={survey.id}>
                    {
                      userSettings.competenciesDone?.includes(survey.category)
                        ?
                        <Button className="category-done" onClick={() => onSelectDoneCategory(survey)}>
                          <div className="img-wrapper">
                            <img
                              src={survey.image?.url}
                              alt={`${survey.name} icon`}
                              role="presentation"
                            />
                          </div>
                          <div className="label">{survey.name}</div>
                          <div className="ribbon" aria-label="completed">
                            <img
                              src={ribbonImg}
                              role="presentation"
                              alt="Ribbon" />
                          </div>
                        </Button>
                        :
                        <Button className="category-link" onClick={() => onSelectCategory(survey)}>
                          <div className="img-wrapper">
                            <img
                              src={survey.image?.url}
                              alt={`${survey.name} icon`}
                              role="presentation"
                            />
                          </div>
                          <div className="button">{survey.name}</div>
                        </Button>
                    }
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
        <div className="footer">
          {allDone
            ?
            <Link className="button" href="/superpower/results" icon={faMask} iconAlign="right">Reveal Results</Link>
            :
            <Link className="link" href="/superpower/save">Save & Leave</Link>
          }
        </div>
      </div>
    );
  else
    return <NotFound />
}

export default CategoriesPage;
