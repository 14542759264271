import useRecaptcha from '../../hooks/useRecaptcha';
import downloadRepository from '../../data/repositories/download'
import { parseUrl, stringifyUrl } from 'query-string';
import atomWithSessionStorage from '../../utils/atomWithSessionStorage';
import { useAtom } from 'jotai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from '../image';
import { trackLinkClick } from '@snowplow/browser-plugin-link-click-tracking';

import './index.css';

const downloadVerificationAtom = atomWithSessionStorage('downloadVerification', { success: false });

const fromatUrl = (url, signatureHash) => {
  const parsedUrl = parseUrl(url);
  parsedUrl.query = {
    ...parsedUrl.query,
    Policy: signatureHash.policy,
    Signature: signatureHash.signature,
    "Key-Pair-Id": signatureHash.keyPairId
  };
  return stringifyUrl(parsedUrl);
};

const extLinkRegEx = /(https?|s?ftp):\/\/|mailto:/g;

const Link = ({ children, href = "/", restricted, header, secondary, type = "default", className, iconAlign = "left", icon, image, ...restProps }) => {
  const isExternal = useRef(href && href.search(extLinkRegEx) !== -1);

  const classNames = useMemo(() => {
    const classNames = [className ?? 'bccampus-link', type];

    if (secondary !== true) classNames.push('primary');
    else classNames.push('secondary');

    if (header === true) classNames.push('header');

    return classNames.join(' ');
  }, [type, header, secondary, className]);

  const calculatedProps = useMemo(() => ({
    ...restProps,
    className: classNames,
    href: href || "/",
    role: "link",
  }), [href, restProps, classNames]);

  const calculatedChildren = <>
    {(iconAlign === "right" && type !== "icon") && <span>{children}</span>}
    {icon &&
      <FontAwesomeIcon icon={icon} className={`bccampus-link-icon align-${iconAlign}`} size={type === "icon" ? "lg" : undefined} />
    }
    {image &&
      <Image icon data={image} className={`bccampus-link-icon align-${iconAlign}`} size={type === "icon" ? "2x" : undefined} />
    }
    {(iconAlign === "left" && type !== "icon") && <span>{children}</span>}
  </>;

  const handleMouseDown = useCallback((e) => {
    if (e.button < 2) trackLinkClick({ targetUrl: href || "/" });
  }, [href]);

  return (
    isExternal.current === true
      ? restricted === true
        ? <RestrictedLink {...calculatedProps}>{calculatedChildren}</RestrictedLink>
        : <a {...calculatedProps} onMouseDown={handleMouseDown}>{calculatedChildren}</a>
      :
      <RouterLink {...calculatedProps}
        to={calculatedProps.href}
        onMouseDown={handleMouseDown}>
        {calculatedChildren}
      </RouterLink>
  );
};

const RestrictedLink = ({ href, children, ...restProps }) => {
  const { execute } = useRecaptcha();
  const [downloadVerification, setDownloadVerification] = useAtom(downloadVerificationAtom);
  const [targetUrl, setTargetUrl] = useState(href || "/");

  // Intercept click event for restricted urls
  const handleClick = async (e) => {
    if (
      downloadVerification.success === false ||
      downloadVerification.expires <= Math.floor((new Date()).getTime() / 1000)
    ) {
      const targetLink = e.currentTarget;

      e.preventDefault();
      e.stopPropagation();

      execute("file_download", async (token) => {
        const validation = await downloadRepository.verify(token);
        if (validation.success === true && validation.score >= 0.5) {
          setDownloadVerification(validation);
          targetLink.href = fromatUrl(href, validation.signatureHash);

          trackLinkClick({ targetUrl: href })
          targetLink.click();
        }
      });
    }
    return true;
  };

  // Replace restricted link URL with a signed URL
  useEffect(() => {
    if (downloadVerification.success === true &&
      downloadVerification.score >= 0.5 &&
      downloadVerification.expires > Math.floor((new Date()).getTime() / 1000) &&
      !!downloadVerification.signatureHash
    ) {
      setTargetUrl(fromatUrl(href, downloadVerification.signatureHash));
    } else {
      setTargetUrl(href || "/");
    }
  }, [href, downloadVerification]);

  return <a {...restProps} href={targetUrl} onClick={handleClick}>{children}</a>;
};

export default Link;
