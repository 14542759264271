import React from 'react';
import Content from './Content'
import Assessment from './assessment'

import GoogleSiteVerification from '../components/google-site-verification';
import GoogleAnalytics from '../components/google-analytics';
import LinkedInInsight from '../components/linkedin-insight';
import HotJar from '../components/hotjar-tracking';
import { Route, Switch } from 'react-router-dom';
import SlidingModal from '../components/sliding-modal';
import { Snowplow } from '../components/snowplow-tracking';

import './App.less';

function App() {
  return (
    <>
      <Snowplow />
      <GoogleSiteVerification />
      <GoogleAnalytics />
      <LinkedInInsight />
      <HotJar />
      <Switch>
        <Route path="/superpower/:page?/:section?/:action?" component={Assessment} />
        <Route path="*" component={Content} />
      </Switch>
      <SlidingModal />
    </>
  );
}

export default App;
