import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { atom, useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from "react";
import Button from "../../../components/button";
import FocusTrap from '../../../components/focus-trap';

export const DialogTypes = {
  message: "message",
  alert: "alert",
  confirm: "confirm",
};
const DialogTypeAriaLabels = {
  message: "Message",
  alert: "Alert",
  confirm: "Confirmation",
};
export const DialogMessageTypes = {
  info: "info",
  warn: "warn",
  error: "error",
  success: "success",
};

export const DialogPositions = {
  default: "default",
  top: "top",
  center: "center",
  bottom: "bottom",
};

const dialogDefaultState = (type = DialogTypes.message) => ({
  open: false,
  position: DialogPositions.default,
  title: null,
  message: null,

  type: DialogTypes.message,
  messageType: DialogMessageTypes.info,
  closeOnClickMask: type === DialogTypes.message ? false : true,
  isDismissable: type === DialogTypes.message ? true : false,
  dismissTimer: type === DialogTypes.message ? 3000 : 0,
  mask: type === DialogTypes.message ? false : true,

  onPositiveAction: null,
  onNegativeAction: null,

  positiveActionLabel: DialogTypes.confirm ? "Yes" : "OK",
  negativeActionLabel: DialogTypes.confirm ? "No" : "Cancel",
  afterOpen: null,
  afterClose: null,
});

const DialogBaseStateAtom = atom(dialogDefaultState());
const DialogInitialStateAtom = atom({});

export const DialogAtom = atom(
  (get) => get(DialogBaseStateAtom),
  (get, set, newOptions) => {
    if (newOptions) {
      if (typeof newOptions === "function") newOptions = newOptions(get(DialogBaseStateAtom));
      newOptions = { ...get(DialogInitialStateAtom), ...newOptions }
      set(DialogBaseStateAtom, { ...dialogDefaultState(newOptions.type), ...newOptions });
    }
  }
);

export function Dialog({ options, ...props }) {
  const setDialogInitialState = useUpdateAtom(DialogInitialStateAtom);
  const [dialogState, setDialogState] = useAtom(DialogAtom);

  const containerRef = useRef();
  const contentRef = useRef();
  const focusTrapRef = useRef(null);

  const handleClickOutside = (e) => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      handleClose();
    }
  };

  const changeOpenState = (newState) => {
    setDialogState(prev => ({ ...prev, open: newState }));
  };

  useEffect(() => {
    const triggerEl = dialogState.dismissTimer === 0 ? document.activeElement : null;
    const containerEl = containerRef.current;
    if (dialogState.open === true) {
      containerEl.addEventListener('click', handleClickOutside);
      contentRef.current.querySelector('.modal-actions .modal-actions-default')?.focus();

      if (dialogState.afterOpen) dialogState.afterOpen();

      if (dialogState.dismissTimer > 0 && dialogState.type === DialogTypes.message) {
        setTimeout(() => changeOpenState(false), dialogState.dismissTimer);
      }
    }
    else if (dialogState.open === false) {
      if (dialogState.afterClose) dialogState.afterClose();
    };

    return () => {
      triggerEl?.focus();
      containerEl.removeEventListener('click', handleClickOutside);
    }
  },
    // eslint-disable-next-line
    [dialogState]
  );

  useEffect(() => {
    if (options) {
      setDialogInitialState(prev => ({ ...prev, ...options }));
    }
  },
    // eslint-disable-next-line
    [options]
  );

  const handlePositiveAction = () => {
    if (!dialogState.onPositiveAction) {
      changeOpenState(false);
      return;
    }

    const result = dialogState.onPositiveAction();
    if (result !== false) changeOpenState(false);
  };

  const handleNegativeAction = () => {
    if (!dialogState.onNegativeAction) {
      changeOpenState(false);
      return;
    }

    const result = dialogState.onNegativeAction();
    if (result !== false) changeOpenState(false);
  };

  const handleClose = () => {
    handleNegativeAction();
  }

  const modalClassNames = useMemo(() => {
    const classNames = [
      "bccampus-dl-assessment-modal",
      dialogState.messageType,
      dialogState.type,
      "pos-" + dialogState.position,
      dialogState.open ? "open" : "close",
    ];

    if (dialogState.isDismissable) classNames.push("dismissable");
    if (dialogState.mask) classNames.push("with-mask");
    if (dialogState.closeOnClickMask) classNames.push("close-onclick-mask");

    return classNames.join(" ");
  }, [dialogState]
  );

  return (
    <div {...props} className={modalClassNames}>
      <div className="modal-container" ref={containerRef}>
        <FocusTrap ref={focusTrapRef}>
          <div className="modal-content" ref={contentRef}
            role={dialogState.type === DialogTypes.message ? "alert" : "alertdialog"}
            aria-modal={dialogState.mask}
            aria-label={dialogState.title ? undefined : DialogTypeAriaLabels[dialogState.type]}
            aria-labelledby={dialogState.title ? "modal-title" : undefined}
            aria-describedby="modal-message"
          >
            {dialogState.title &&
              <div id="modal-title" className="heading">{dialogState.title}</div>
            }
            <div className="modal-tools">
              {(dialogState.isDismissable && dialogState.type === DialogTypes.message) && <Button className="button" type="icon" icon={faTimes} onClick={handleClose} />}
            </div>
            {dialogState.messageType !== DialogMessageTypes.info &&
              <div className="modal-icon">
                {dialogState.messageType === DialogMessageTypes.error && <FontAwesomeIcon icon={faExclamationCircle} size="2x" />}
                {dialogState.messageType === DialogMessageTypes.warn && <FontAwesomeIcon icon={faExclamationCircle} size="2x" />}
                {dialogState.messageType === DialogMessageTypes.success && <FontAwesomeIcon icon={faCheckCircle} size="2x" />}
              </div>
            }
            <div id="modal-message">{dialogState.message}</div>

            {dialogState.type === DialogTypes.alert &&
              <div className="modal-actions">
                <Button className="button modal-actions-default" type="button" onClick={handlePositiveAction}>
                  {dialogState.positiveActionLabel}
                </Button>
              </div>
            }

            {dialogState.type === DialogTypes.confirm &&
              <div className="modal-actions">
                <Button className="button" type="button negative" onClick={handleNegativeAction}>
                  {dialogState.negativeActionLabel}
                </Button>
                <Button className="button modal-actions-default" type="button positive" onClick={handlePositiveAction}>
                  {dialogState.positiveActionLabel}
                </Button>
              </div>
            }
          </div>
        </FocusTrap>
      </div>
    </div>
  );
}



Dialog.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.node,
    message: PropTypes.node,
    type: PropTypes.oneOf(Object.keys(DialogTypes)),
    messageType: PropTypes.oneOf(Object.keys(DialogMessageTypes)),
    mask: PropTypes.bool,
    isDismissable: PropTypes.bool,
    closeOnClickMask: PropTypes.bool,
    dismissTimer: PropTypes.number,
    onPositiveAction: PropTypes.func,
    onNegativeAction: PropTypes.func,
    afterOpen: PropTypes.func,
    afterClose: PropTypes.func,
  }),
};

export default Dialog;
