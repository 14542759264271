import Link from "../../../components/link";
import mapImg from '../assets/treasure-map.png';

function NotFound() {

  return (
    <div className="default">
      <div className="content" style={{ display: "flex", flexDirection: "column", gap: "4em", alignItems: "center" }} >
        <div className="heading" >
          Not found!
        </div>
        <img src={mapImg} alt="Content not found" style={{ width: "15em" }} />
        <div style={{ textAlign: "center" }} >
          We've led our hero down the wrong path. Return to safety!
        </div>
        <Link className="button" type="button" href="/">Go home</Link>
      </div>
    </div>
  );
}

export default NotFound;
