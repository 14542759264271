import { useMemo } from 'react';
import './index.css';

function Horizontal({ children, tight, align, as, className, gap, style }) {

  const classNames = useMemo(() => {
    const classNames = ['bccampus-horizontal']
    if (className) classNames.push(className);
    if (align && typeof align === "string") {
      classNames.push(align.trim().split(/\s+/).map(p => "align-" + p).join(' '));
    }
    return classNames.join(' ');

  }, [className, align]);

  const styles = useMemo(() => {
    let styles = { gap: gap ?? "calc(var(--padding)/2)" }
    if (style) styles = { ...styles, ...style };
    if (tight === true) styles = { ...styles, gap: "calc(var(--padding)/8)" };

    return styles;
  }, [style, tight, gap]);

  return (
    as !== undefined
      ?
      as({ ...arguments[0], className: classNames, as: undefined, style: styles })
      :
      <div className={classNames} style={styles}>
        {children}
      </div>
  )
}

Horizontal.Spacer = function () {
  return (
    <div className='spacer'></div>
  )
}

const HorizontalPart = function ({ className, children, size, stretched, double, centered, truncateText, style }) {

  const classNames = useMemo(() => {
    const classNames = [className]
    if (stretched === true) classNames.push('stretched');
    if (double === true) classNames.push('double');
    if (centered === true) classNames.push('centered');
    if (truncateText === true) classNames.push('truncate-text');
    if (size !== null) {
      classNames.push(size);
    }

    return classNames.join(' ');
  }, [className, stretched, double, centered, truncateText, size]);

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  )
}

Horizontal.Part = HorizontalPart;

export default Horizontal;
