import BaseAtomFactory from '../../utils/BaseAtomFactory';
import repository from '../repositories/survey-responses';

const operations = {
  __name: "survey-responses",
  read: async (action) => {
    return [await repository.read(action.data.surveySession)];
  },
  create: async (action) => {
    return [await repository.create(action.data)];
  },
}

const surveyResponsesAtomFamily = new BaseAtomFactory(operations, null, { initialize: false, rereadOnMount: false });
export default surveyResponsesAtomFamily;
