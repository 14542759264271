const focusableTags = ["A", "BUTTON", "INPUT", "TEXTAREA", "SELECT", "DETAILS"];
const selector = "a,button,input,textarea,select,details,[tabindex]";

function getFocusableChilds(element = document) {
  return [...element.querySelectorAll(selector)].filter(el => !el.hasAttribute("disabled") && el.getAttribute("tabindex") !== "-1" && !el.getAttribute('aria-hidden'));
}

function canFocusable(element = document, acceptNegativeTabindex = true) {
  if (focusableTags.includes(element.tagName)) return true;
  if (element.hasAttribute("tabindex")) {
    return (!element.hasAttribute("disabled") && (acceptNegativeTabindex || element.getAttribute("tabindex") !== "-1") && !element.getAttribute('aria-hidden'));
  }
  return false;
}

export { getFocusableChilds, canFocusable };
