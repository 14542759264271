
const formatPerson = (person, options = {}) => {
  if (!person.firstName && !person.lastName) return "Malformed Data";

  const fullname = person.firstName + " " + (person.middleName ? person.middleName + " " : "") + person.lastName;
  const institution =
    options.includeOrganization === true && person.organization ? " (" + person.organization.name + ")" : "";

  return fullname + institution;
};

export const transformContributor = (contributor) => {
  switch (contributor.discriminator) {
    case "person":
      return contributor.person && formatPerson(contributor.person);
    case "organization":
      return contributor.organization ? contributor.organization.name : "";
    default:
      return "Unknown contributor type";
  }
};

export const transformConcept = (concept) => {
  return concept.name;
};


export const flattenContributors = (
  contributors,
  options = {}
) => {
  return contributors?.reduce((flatten, contributor) => {
    if (!contributor?.relationship?.code) return flatten;
    contributor.name = transformContributor(contributor);
    const entry = options.onlyNames === true ? contributor.name : contributor;
    if (!flatten[contributor.relationship.code]) {
      flatten[contributor.relationship.code] = [entry];
    } else {
      flatten[contributor.relationship.code].push(entry);
    }

    return flatten;
  }, {});
};

export const flattenConcepts = (conceptPaths, options = {}) => {
  return conceptPaths?.reduce((flatten, path) => {
    if (!path?.scheme?.code) return flatten;

    flatten[path.scheme.code] = options.onlyNames === true ? path.concepts.map(transformConcept) : path.concepts;

    return flatten;
  }, {});
};

export const getImageURL = (image) => image?.formats?.small?.url || image?.formats?.thumbnail?.url || image?.url;

export const formatImage = (image, format) => {
  if (!image || typeof image !== "object") return null;

  if (image.formats && format) {
    if (format === "thumbnail")
      return (image.formats["thumbnail"] || image.formats["small"] || image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "small")
      return (image.formats["small"] || image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "medium")
      return (image.formats["medium"] || image.formats["large"] || image).url;
    if (format === "large")
      return (image.formats["large"] || image).url;
  }

  return image.url;
};

export const formatResourceName = (resource, shortForm = false) => {
  let name = `${resource.name}${resource.meta.subtitle ? `: ${resource.meta.subtitle}` : ""}`;

  if (shortForm !== true) {
    name += `${resource.meta.edition ? ` - ${resource.meta.edition}` : ""}${resource.meta.publisher ? ` (${resource.meta.publisher})` : ""}`;
  }

  return name;
};
