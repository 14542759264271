import { useRef, useState } from "react";
import Button from "../../../components/button";

function SimpleForm({ placeholder, submitLabel, onSubmit, onChange, beforeSubmit, inputStyle }) {
  const inputRef = useRef(null);
  const [error, setError] = useState(null);

  const validate = (value) => {
    if (!value) return false;

    const result = (typeof beforeSubmit !== "function") ? true : beforeSubmit(value);

    if (typeof result === "boolean") return result;
    else if (typeof result === "object" && result.error) {
      setError(result.error);
      return false;
    }

    return true;
  }

  const handleSubmit = () => {
    const inputValue = inputRef.current.value.trim();
    setError(null);
    inputRef.current.focus();
    if (validate(inputValue) && typeof onSubmit === "function") {
      onSubmit(inputValue);
    }
  }

  return (
    <div style={{ display: "flex", gap: "0.5em", flexDirection: "column", alignItems: "center" }} >
      <input ref={inputRef}
        type="text"
        placeholder={placeholder}
        style={inputStyle}
        onKeyDown={(e) => { if (e.code === "Enter") handleSubmit(); }}
        onChange={onChange}
      />
      {!!error && <div className="paragraph error">{error}</div>}
      <Button className="button" onClick={handleSubmit}>{submitLabel}</Button>
    </div>
  );
}

export default SimpleForm;
