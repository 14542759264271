import { Typography } from 'antd';
import { useMemo } from 'react';
import Link from '../../link';
import Image from '../../image';

import './index.css';

function FilterBox({ id, label, extra, color, image, style, onClick, href, ...restProps }) {
  const _color = color || "#445566";

  const classNames = useMemo(() => {
    const classNames = ['bccampus-search-filter-box', label.trim().toLowerCase().replace(/[^a-z0-9]+/g, '-')]
    return classNames.join(' ');
  }, [label]);

  const styles = useMemo(() => ({
    border: `2px solid ${_color}`,
    color: _color,
  }), [_color]);

  return (
    <Link type="competency" id={id} className={classNames}
      href={href}
      {...restProps}
    >
      <div className="bccampus-search-filter-box-icon">
        <Image data={image} icon size="6x" alt={`${label} icon`} />
      </div>

      <div className="bccampus-search-filter-box-label"
        style={{ ...style, color: styles.color, border: styles.border }}>
        <Typography.Text style={{ color: styles.color }}>
          {label}
        </Typography.Text>
      </div>
    </Link>
  )
}

export default FilterBox;
