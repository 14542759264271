import { useLayoutEffect, useRef } from "react";
import MultipleChoice from "./multiple-choice";
import Scale from "./scale";

function Question({ id, content, answer, answerKey, readonly, onChangeAnswer, result, showExplanation = false }) {
  const sectionRef = useRef();

  const handleChange = (value) => {
    if (typeof onChangeAnswer === "function") {
      onChangeAnswer(content.__component, id, value);
    }
  }

  useLayoutEffect(() => sectionRef.current?.focus(), [id]);

  return (
    <div className="content">
      <div className="question section" ref={sectionRef} tabIndex={-1}>
        <div className="heading">{content.header}</div>
        <div className="text" dangerouslySetInnerHTML={{ __html: content.text }} />
        {
          content.__component === "question.multiple-choice"
            ?
            <MultipleChoice
              choices={content.choices}
              value={answer}
              readonly={readonly}
              answerKey={answerKey}
              multipleSelect={content.multipleSelect}
              result={result}
              showExplanation={showExplanation}
              explanation={content.explanation}
              onChange={handleChange}
            />
            :
            <Scale
              marks={content.marks}
              value={answer}
              readonly={readonly}
              answerKey={answerKey}
              result={result}
              showExplanation={showExplanation}
              explanation={content.explanation}
              onChange={handleChange} />
        }
      </div>
    </div>
  );
}

export default Question;
