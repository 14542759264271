import { useMemo } from 'react';
import './index.css';

const Heading = ({ children, feature, className, level = 1, ...restProps }) => {

    const classNames = useMemo(() => {
        const classNames = ['bccampus-typography-heading', `level-${level}`];
        if (className) classNames.push(className);

        if (feature === true) classNames.push('feature');

        return classNames.join(' ');
    }, [level, feature, className]);

    const props = {
        ...restProps,
        className: classNames
    };

    switch (level) {
        case 1:
            return <h1 {...props}>{children}</h1>
        case 2:
            return <h2 {...props}>{children}</h2>
        case 3:
            return <h3 {...props}>{children}</h3>
        case 4:
            return <h4 {...props} >{children}</h4>
        case 5:
            return <h5 {...props} >{children}</h5>
        case 6:
            return <h6 {...props}>{children}</h6>
        default:
            return <div {...props} >{children}</div>
    }
}

const Paragraph = ({ children, strong, className, ...restProps }) => {
    const classNames = useMemo(() => {
        const classNames = ['bccampus-typography-paragraph'];
        if (className) classNames.push(className);
        if (strong === true) classNames.push('strong');

        return classNames.join(' ');
    }, [strong, className]);

    const props = {
        ...restProps,
        className: classNames
    }

    return <p {...props}>{children}</p>
}

const Text = ({ children, className, strong, ...restProps }) => {
    const classNames = useMemo(() => {
        const classNames = ['bccampus-typography-text'];
        if (className) classNames.push(className);
        if (strong === true) classNames.push('strong');

        return classNames.join(' ');
    }, [strong, className]);

    const props = {
        ...restProps,
        className: classNames
    }

    return <span {...props}>{children}</span>
}

const Label = ({ children, ...restProps }) => {

    const props = {
        ...restProps,
        className: `bccampus-typography-label`
    }

    return <span {...props}>{children}</span>
}

const Subtitle = ({ children, ...restProps }) => {

    const props = {
        ...restProps,
        className: `bccampus-typography-subtitle`
    }

    return <div {...props}>{children}</div>
}

const Typography = { Heading, Paragraph, Text, Label, Subtitle };

export { Heading, Paragraph, Text, Label, Subtitle };
export default Typography;