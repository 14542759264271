import BaseAtomFactory from '../../utils/BaseAtomFactory';
import repository from '../repositories/survey-sessions';

const operations = {
  __name: "survey-session",
  read: async (action) => {
    return [await repository.read(action.code)];
  },
  create: async () => {
    return [await repository.create()];
  },
  sendCode: async (action) => {
    return [await repository.sendCode(action.data.code, action.data.email)];
  },
  sendResults: async (action) => {
    return [await repository.sendResults(action.data.code, action.data.email)];
  },
}

const surveySessionsAtom = new BaseAtomFactory(operations, null, { initialize: false, rereadOnMount: false });
export default surveySessionsAtom;
