import { Helmet } from 'react-helmet';
import { Suspense } from 'react';

import ErrorBoundary from '../../components/error-boundary';
import Fallback from '../../components/suspense-fallback';

import SaveSessionPage from './pages/save-session';
import AssessmentResultPage from './pages/assessment-result';

import './index.css';
import CreditsPage from './pages/credits';
import Dialog from './components/dialog';
import useUserSettings from '../../hooks/useUserSettings';
import SessionPage from './pages/session';
import StartPage from './pages/start';
import { Snowplow } from '../../components/snowplow-tracking';
import GoogleSiteVerification from '../../components/google-site-verification';

function Content({ params }) {
  const { userSettings } = useUserSettings();

  if (params.page === "session" && !!params.section) {
    return <StartPage
      redirectPath="/superpower"
      sessionCode={params.section || userSettings.session?.code}
    />;
  }

  if (userSettings.session?.code && (Date.now() - userSettings._lastUpdatedAt) < 900000) {
    if (params.page === "save") return <SaveSessionPage />;
    else if (params.page === "results") return <AssessmentResultPage section={params.section} />;
    else return <SessionPage code={userSettings.session.code} />;
  }

  if (params.page === "results" && !!params.section) {
    return <StartPage
      redirectPath="/superpower/results"
      sessionCode={params.section}
    />;
  }

  if (params.page === "credits") return <CreditsPage />;

  return <StartPage
    redirectPath="/superpower"
    sessionCode={params.section || userSettings.session?.code}
  />;
}

function Assessment({ match }) {

  return (
    <>
      <Helmet>
        <title>B.C. Digital Literacy Superpower</title>
        <meta
          name="description"
          content="B.C. Digital Literacy superpower discovery tool." />
      </Helmet>
      <GoogleSiteVerification />
      <Snowplow />
      <div id="bccampus-dl-assessment">
        <ErrorBoundary>
          <Suspense fallback={<Fallback />}>
            <Content params={match.params} />
          </Suspense>
          <Dialog />
        </ErrorBoundary>
      </div>
    </>
  );
}

export default Assessment;
