import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../../../components/link';

import surveyResponsesAtom from '../../../data/atoms/survey-responses';
import { useUpdateAtom, useAtomValue } from 'jotai/utils';
import SurveyAtomFactory from '../../../data/atoms/surveys';
import Question from '../components/question';
import Header from '../components/header';
import useUserSettings from '../../../hooks/useUserSettings';
import Button from '../../../components/button';
import { useState } from 'react';
import NotFound from '../components/not-found';
import { DialogAtom, DialogTypes } from '../components/dialog';
import { faArrowLeft, faChevronRight, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleLeft, faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

function QuestionsPage() {
  const surveys = useAtomValue(SurveyAtomFactory);
  const { userSettings, saveUserSettings, resetUserSettings } = useUserSettings();
  const dispatchResponseAction = useUpdateAtom(surveyResponsesAtom);
  const setDialogState = useUpdateAtom(DialogAtom);

  const [currentAnswers, setCurrentAnswers] = useState(userSettings?.competencyInProgress?.answers ?? {});
  const [showExplanation, setShowExplanation] = useState(false);

  if (!userSettings.competencyInProgress) return null;

  const { key, id, page, done, answerKeys = {} } = userSettings.competencyInProgress;

  const category = surveys.data.find(survey => survey.category === key);
  const question = category?.questions[page];

  const isFirst = page === 0;
  const isLast = category?.questions.length === page + 1;

  const handleChangeAnswer = (questionType, questionId, answer) => {
    setCurrentAnswers(prev => {
      const typeAnswers = prev[questionType] ?? {};

      return { ...prev, [questionType]: { ...typeAnswers, [questionId]: answer } };
    });
  }
  const handleChangePage = (page) => {
    setShowExplanation(false);
    saveUserSettings({ competencyInProgress: { page, answers: currentAnswers } }, true);
  }
  const onContinue = () => {
    handleChangePage(page + 1);
  }

  const onFinish = () => {
    if (done !== true) {

      dispatchResponseAction({
        type: "create",
        data: {
          survey: id,
          surveySession: userSettings.session.code,
          answers: currentAnswers,
        },
        callback: (newResponsesState) => {
          if (newResponsesState.success) {
            saveUserSettings({
              responses: [newResponsesState.data],
              competencyInProgress: {
                page: -1,
                done: true,
                score: newResponsesState.data.score,
                answers: newResponsesState.data.answers,
                answerKeys: newResponsesState.data.survey.questions.reduce((keys, question) => {
                  if (question.__component === "question.multiple-choice")
                    keys[question.__component] = {
                      ...keys[question.__component],
                      [question.id]: question.choices.map(c => c.id)
                    };

                  return keys;
                }, {}),
              },
              competenciesDone: [key],
            }, true);
          }
        }
      });
    }
    else {
      saveUserSettings({ competencyInProgress: { page: -1 }, }, true);
    }
  }

  if (!question) return <NotFound />;

  const answerKey = answerKeys[question.__component]?.[question.id];
  const answer = currentAnswers[question.__component]?.[question.id];
  const isAnswerCorrect = () => {
    if (!Array.isArray(answerKey)) return;
    if (!answer) return 0;

    if (question.multipleSelect === true) {
      for (let index = 0; index < answer.length; index++) {
        const selectedChoice = answer[index];

        if (!answerKey.includes(selectedChoice)) return 0;
      }
      return answer.length === answerKey.length ? 100 : Math.round(answer.length / answerKey.length);
    }
    else {
      return answerKey.includes(answer) ? 100 : 0;
    }
  };

  const questionResult = isAnswerCorrect();

  return (
    <div className="default">
      <Header
        left={{
          icon: faArrowLeft,
          onClick: () => {
            isFirst
              ? saveUserSettings({ competencyInProgress: undefined })
              : handleChangePage(page - 1);
          }
        }}

        right={{
          icon: faRotateLeft,
          label: "Start over",
          onClick: () => {
            setDialogState({
              open: true,
              type: DialogTypes.confirm,
              title: "Start Over",
              message: "Are you sure?",
              onPositiveAction: resetUserSettings,
            });
          },
        }}
      />
      <Question
        id={question.id}
        content={question}
        answer={answer}
        answerKey={answerKey}
        result={questionResult}
        showExplanation={showExplanation}
        readonly={done}
        onChangeAnswer={handleChangeAnswer}
      />
      <div className="footer">
        {done === true &&
          <div>
            <Button
              className="button"
              onClick={() => setShowExplanation(prev => !prev)}
              icon={showExplanation !== true ? faCircleQuestion : faCircleLeft}
            >
              {showExplanation !== true ? "Explain this" : "Return to question"}
            </Button>
          </div>
        }
        <div>
          <Button
            className="button"
            onClick={isLast ? onFinish : onContinue}
          >
            {isLast ? "Finish " : "Continue "}
            < FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
        <div>
          <Link className="link" href="/superpower/save">Save & Leave</Link>
        </div>
      </div>
    </div>
  );
}

export default QuestionsPage;
