import React, { Suspense } from 'react';
import { AppBar, AppContent, ContentContainer, Viewport } from '../components/layout';
import Header from '../components/header';
import ErrorBoundary from '../components/error-boundary';
import Footer from '../components/footer';
import Fallback from '../components/suspense-fallback';

import prerenderedLoadable from "../utils/prerendered-loadable";
import { Switch, Route } from "react-router-dom";
import NotFound from "./not-found";
import { Helmet } from 'react-helmet';

const Home = prerenderedLoadable(() => import('./home'));
const TeachIt = prerenderedLoadable(() => import('./teach-it'));
const LearnIt = prerenderedLoadable(() => import('./learn-it'));
const About = prerenderedLoadable(() => import('./about'));
const Contact = prerenderedLoadable(() => import('./forms/contact-page.js'));
// const Sitemap = prerenderedLoadable(() => import('./sitemap'));
const Page = prerenderedLoadable(() => import('./page'));
const ResourceDetails = prerenderedLoadable(() => import('./resource-details'));
const CompetencyPage = prerenderedLoadable(() => import('./competency-page'));
const ConceptPage = prerenderedLoadable(() => import('./concept-page'));

function Content() {
  return (
    <Viewport className="bccampus-viewport">
      <Helmet>
        <title>B.C. Digital Literacy</title>
        <meta
          name="description"
          content="B.C. Digital Literacy Project" />
      </Helmet>
      <AppBar><Header /></AppBar>
      <AppContent>
        <ErrorBoundary>
          <div id="content-top" />
          <ContentContainer scrollable>
            <Suspense fallback={<Fallback />}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/learn-it" component={LearnIt} />
                <Route path="/teach-it" component={TeachIt} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/not-found" component={NotFound} />
                {/* <Route path="/sitemap" component={Sitemap} /> */}

                <Route path="/page/:code" component={Page} />
                <Route path="/resources/:id" component={ResourceDetails} />
                <Route path="/competencies/:id" component={CompetencyPage} />
                <Route path="/skills/:id" component={ConceptPage} />


                {/* <Route path="*" component={URLResolver} /> */}
              </Switch>
            </Suspense>
            <Footer />
          </ContentContainer>
        </ErrorBoundary>
      </AppContent>
    </Viewport>
  );
}

export default Content;
