import apiRequest from '../../utils/bccampusGraphQLRequest';
import VerifyDownloadQuery from '../queries/Download.graphql';

const verify = async (token) => {
    const variables = {
        token: token,
    };
    const result = await apiRequest(VerifyDownloadQuery, variables);
    return result.verifyDownload;
}
const repository = { verify };

export { verify }
export default repository;
