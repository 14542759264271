import { Row, Col } from 'antd';
import PageSection from '../page-section';
import { Space } from "antd";
import Link from '../link';
import { Heading } from '../typography';

import './index.css';
import BCcampusBadge from '../bccampus-badge';

import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import BCMinistryBadge from '../bc-ministry-badge';

function Footer() {
  return (
    <footer>
      <PageSection type="dark" className="bccampus-site-footer">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <Heading level={3}>Connect</Heading>
            <div>
              <Link type="button" href="/contact">Contact BCcampus</Link>
            </div>
            <Space wrap size="middle" style={{ marginTop: "1em" }}>
              <Link type="icon" aria-label="Twitter" icon={faXTwitter} href="https://twitter.com/BCcampus/" target="_blank" />
              <Link type="icon" aria-label="LinkedIn" icon={faLinkedin} href="https://ca.linkedin.com/company/bccampus" target="_blank" />
              <Link type="icon" aria-label="Kaltura" icon={faPlayCircle} href="https://media.bccampus.ca/" target="_blank" />
            </Space>
          </Col>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <Heading level={3}>Digital Learning</Heading>
            <Space direction="vertical">
              <Link target="_blank"
                href="https://www2.gov.bc.ca/gov/content/education-training/post-secondary-education/institution-resources-administration/digital-learning-strategy">
                Digital Learning Strategy
              </Link>
              <Link target="_blank"
                href="https://www2.gov.bc.ca/assets/gov/education/post-secondary-education/institution-resources-administration/digital-learning-strategy/bc_post-secondary_digital_literacy_framework.pdf">
                Digital Literacy Framework
              </Link>
            </Space>
          </Col>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <Heading level={3}>Funded by</Heading>
            <BCMinistryBadge reverse />
          </Col>
          <Col xs={24} md={12} xl={6} className="bccampus-site-footer-section">
            <Heading level={3}>Created by</Heading>
            <BCcampusBadge />
          </Col>
        </Row>
      </PageSection>
      <PageSection type="dark-alt" className='bccampus-site-footer-band'>
        <Row gutter={[24, 6]}>
          <Col xs={24}>
            <Space size={16} split="|" wrap>
              <span>This site is licensed as CC-BY except where otherwise noted.</span>
              <Link href="https://bccampus.ca/privacy/" target="_blank">Privacy Statement</Link>
              <Link href="/page/disclaimer">Disclaimer</Link>
            </Space>
          </Col>
        </Row>
      </PageSection>
    </footer>
  )
}

export default Footer;
