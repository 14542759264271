import { Tag, Typography } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './index.css';
import { useMemo } from 'react';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

function FilterTag({
  id, checked, label, hitCount, onChange, checkable, focused,
  maxWidth = "100%", icon = true, style, ellipsis, title, value, ...restProps
}) {

  const classNames = useMemo(() => {
    const classNames = ['bccampus-search-filter-tag', label.trim().toLowerCase().replace(/[^a-z0-9]+/g, '-')]
    if (checkable) classNames.push('checkable');
    if (checked) classNames.push('checked');
    if (focused) classNames.push('focused');
    return classNames.join(' ');
  }, [label, checkable, checked, focused]);

  const handleCheck = () => {
    if (onChange) onChange(!checked, value, hitCount);
  }

  const handleKeyPress = (e) => {
    if (e.code === "Space" || e.code === "Enter") {
      e.preventDefault();
      if (checkable) handleCheck();
    }
  };

  return (
    <Tag id={id} className={classNames} {...restProps}
      icon={icon && <FontAwesomeIcon icon={checked ? faMinusCircle : faPlusCircle} />}
      onClick={checkable && handleCheck}
      onKeyDown={handleKeyPress}
      style={ellipsis ? { ...style, maxWidth: maxWidth } : style}
      title={!!title ? title : `${label} ${hitCount ? `(${hitCount})` : ""}`}
      value={value}
      tabIndex={checkable ? -1 : undefined}
      role={checkable ? "option" : undefined}
      aria-selected={checkable ? checked : undefined}
    >
      <Typography.Text ellipsis={ellipsis} style={ellipsis ? { maxWidth: icon ? "calc(100% - 1em)" : "100%" } : undefined} aria-hidden>
        {label}
        {(hitCount) && <span className="bccampus-search-filter-tag-count">({hitCount})</span>}
      </Typography.Text>
    </Tag>
  )
}

export default FilterTag;
